@import "../.././assets/styles/mainVariables";
@import "../.././assets/styles/fonts";

.compare_page_wrapper{
  width: #{$mainContent};
  margin: 80px auto;

  .sc-kDvujY{
    height: unset;
  }

  .page-title{
    font-size: 24px;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0;
    text-align: left;
    margin-bottom: 28px;
  }
  .compare-empty-view{
    width: 100%;
    height: 400px;
    display: flex;
    align-self: center;
    justify-content: center;
    .empty-compare{
      align-self: center;
      justify-content: center;
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      text-align: center;
      color: #C1C1C1;
    }
  }
    .compare-products-wrapper{
      width: 100%;
      display: flex;
      .compare-products-description{
        border: 1px solid #F4F0EB;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin-bottom: 6px;
        .products-text{
          height: 267px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          .inform_block{
            width: 100%;
            height: calc(100% / 4);
            display: flex;
            align-items: center;
            justify-content: center;
            border-bottom: 1px solid #F4F0EB;
            font-size: 15px;
            font-weight: 500;
            line-height: 18px;
            color: $brandColor;
            white-space: nowrap;
            padding: 0 10px;
            max-width: 140px;
            text-overflow: ellipsis;
            overflow: hidden;
            &:nth-child(even){
              background-color: #FBFAF9;
            }
            &:first-child{
              border-top: 1px solid #F4F0EB;
            }
            &:last-child{
              border-bottom: unset;
            }
          }
        }
      }
      .compare-products{
        display: flex;
        overflow-x: scroll;
        border-right: 1px solid #F4F0EB;
      }
      .wrappers-width{
        width: 84%;
      }
      ::-webkit-scrollbar {
        background: transparent;
        height: 6px;
      }

      ::-webkit-scrollbar-track {
        background: transparent;
      }

      ::-webkit-scrollbar-thumb {
        -webkit-border-radius: 10px;
        border-radius: 10px;
        position: relative;
        background: #EAE1D8;
      }
    }

  @media screen and (max-width: 1050px){
    padding: 20px;
  }
}
