@import 'mainVariables';

.ant-select {
  border: 1px solid $brandColor;

  &.ant-select-single {
    //border: none;
  }

  &.invalid .ant-select-selection {

    border-color: #FF0000;
  }

  .ant-select-selection__placeholder {
    color: #9b9b9b;
    font-size: 13px;
  }

  .ant-select-selection--single {
    height: 35px;

    .ant-select-selection__rendered {
      line-height: 33px;

      &:focus, &:hover, &:active {
        outline: none;
      }
    }
  }

  .ant-select-search input {
    outline: none !important;
    padding: 6px 0;
  }

  .ant-select-selector {
    height: 100% !important;
    border: 0 !important;
    padding: 8px 15px !important;

    .ant-select-selection-placeholder {
      display: flex;
      align-items: center;
      color: black;
      font-size: 14px;
    }
  }

  &.ant-select-focused {
    .ant-select-selector {
      //border: 0 !important;
      box-shadow: 0 0 0 0 white !important;
      border: 1px solid #CDBB99!important;

    }
  }
}

.ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: 0 0 0 3px #D0E3F3;
}


.ant-select-dropdown-menu-item {
  background-color: white;

  &:not(:last-child) {
    border-bottom: 1px solid #DDDDDD;
  }

  &:hover {
    background-color: rgba(179, 179, 179, 0.38) !important;
  }
}

.ant-select-dropdown-menu-item-active {
  background-color: rgba(255, 255, 255, 0.38) !important;
}

.ant-dropdown-menu-item:hover {
  background-color: #f1f1f1;
}

.ant-dropdown-menu-item:hover {
  background-color: #f1f1f1;
}


.ant-calendar-date {
  transition: all 0.3s ease;

  &:hover {
    color: white;
    background-color: #FF0000;
  }
}


.ant-popover-content {
  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner {
    //border-radius: 8px;
    //border: 1px solid #DDDDDD;

    .ant-popover-inner-content {
      padding: 0;

    }
  }
}

.ant-dropdown-trigger {
  display: flex;
  align-items: center;
  color: black;

  &.upload-btn {
    padding-right: 0;
  }

  @media all and (max-width: 1000px) {
    cursor: pointer;
  }
}

.ant-modal {
  width: 50%;
  transform-origin: unset !important;
  animation-duration: unset !important;
}

.ant-breadcrumb {
  font-size: 16px;
  font-weight: 500;

  ol {
    li {
      display: flex;
      align-items: center;
    }
  }

  a {
    color: black;

    &:hover {
      color: $brandColor;
    }
  }

  .ant-breadcrumb-separator {
    margin: 0 10px;

    span {
      position: relative;

      div {
        position: relative;
        top: 50%;
        display: inline-block;
        width: 4px;
        height: 4px;
        background: black;
        transform: translateY(-50%);
      }
    }
  }
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner {
}

.ant-dropdown {
  .ant-dropdown-menu {
    //.ant-dropdown-menu-item-divider {
    //  margin: 4px 12px 25px 12px!important;
    //}

    .ant-dropdown-menu-title-content {
      .user {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .user_info {
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          align-items: flex-start;
          margin-left: 12px;

          .fullName {
            font-size: 15px;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: 0.02em;
          }

          .email {
            font-size: 10px;
            font-weight: 400;
            line-height: 12px;
          }
        }
      }

      .users_tools {
        width: 86%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        svg {
          &:hover {
            path {
              fill: $brandColor;
            }
          }
        }

        .tools-link {
          width: 78%;
          font-size: 13px;
          font-weight: 400;
          color: black;
          line-height: 18px;
          letter-spacing: 0.015em;
        }

        .count{
         position: relative;
          p{
            color: white;
            background-color: #E34256;
            width: 18px;
            height: 18px;
            border-radius: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            font-weight: 500;
            line-height: 9px;
            position: absolute;
            top: -6px;
            left: 12px;
          }
        }

        &:hover {
          .tools-link {
            color: $brandColor;
          }
        }
      }
    }
  }
}

.ant-select-selection-item {
  line-height: 26px !important;
}


.notification-success, .notification-error {

  .ant-notification-notice-close{
    display: none;
  }
  .ant-notification-notice-description {
    color:  #1F2226 !important;
  }

  .ant-notification-notice-icon {
    top: 50%;
    transform: translateY(-50%);
    height: 25px;
    width: 25px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.notification-success {
  background: #E5EFE5 !important;
}

.notification-error {
  background: #FFE3E3 !important;
}

//.ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title{
//  padding: 4px 25px!important;
//}

.ant-checkbox-checked::after{
  border: 1px solid #CDBB99!important;
}
.ant-checkbox-checked .ant-checkbox-inner{
  background-color: white!important;
  border-color: #CDBB99!important;
}
.ant-checkbox-checked .ant-checkbox-inner::after{
  border: 2px solid #CDBB99!important;
  border-top: 0!important;
  border-left: 0!important;
}
.ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner{
  border-color: #CDBB99!important;
}
.currency_menu_item{
  span{
    margin-right: 6px;
  }
}

.ant-pagination{
  margin-bottom: 40px!important;
}

.ant-select-selection-placeholder{
  color:#858585 !important;
}

.ant-dropdown-menu-title-content > a::after{
  position: unset!important;
}

.callDropDown{
  .ant-dropdown-menu-title-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    a{
      display: flex;
      align-items: center;
      margin-bottom: 4px;
      svg{
        margin-right: 8px;
      }
      &:first-child{
        svg{
          margin-right: 6px!important;
        }
      }
    }
  }
}
.callDropDown-slider{
  .ant-dropdown-menu-title-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    a{
      display: flex;
      align-items: center;
      margin-bottom: 4px;
      svg{
        margin-right: 8px;
      }
      &:first-child{
        svg{
          margin-right: 6px!important;
        }
      }
    }
  }
}

.contact-dropDown{
  width: 280px;
  display: flex;
  align-items: center;
  justify-content: start;
}

.ant-picker-today-btn{
  color: $brandColor!important;
}
.ant-picker:hover, .ant-picker-focused{
  border-color: $brandColor!important;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before{
  border: 1px solid $brandColor!important;
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner{
  background: $brandColor!important;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner{
  background: #F6F3ED!important;
}

.new_badgs{
  width: 66px;
  height: 36px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #DECBA7;
  color: white;
  padding: 0 7.2px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.view-modal{
  .ant-modal-content{
    position:fixed;
    top: 0;
    left: 0;
    .ant-modal-body{
      width: 100vw;
      height: 120vh;
      padding: unset;
      .iframe-wrapper{
        width: 100%;
        height: 100%;
        iframe{
          width: 100%;
          height: 100%;
        }
      }
    }
    .ant-modal-footer{
      display: none;
    }
  }
}

.ant-picker{
  border-radius: unset!important;
}
.ant-modal{
  transform-origin: unset !important;
  animation-duration: unset !important;
}

.region{
  display: flex;
  flex-direction: column;
  .regions-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    p{
      width: 20px;
      height: 20px;
    }
    .checked-block{
      display: flex;
      align-items: center;
      p{
        width: 20px;
        margin-right: 10px;
      }
      span{
        font-size: 15px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0;
        text-align: left;
        color: #1F2226;
        margin-bottom: 10px;
      }
    }
  }
  .residences{
    display: flex;
    flex-direction: column;
    margin-left: 32px;
    .residences-block{
        display: flex;
        align-items: center;
        margin-top: 10px;
        p{
          width: 20px;
          margin-right: 8px;
          margin-bottom: unset;
        }
        span{
          font-size: 13px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: 0.015em;
          text-align: left;
          color: #1F2226;
          margin-bottom: 6px;
        }
    }
  }
}

.home_region{
  display: flex;
  flex-direction: column;
  .regions-header{
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    .checked-block{
      display: flex;
      align-items: baseline;
      p{
        width: 16px;
        margin-right: 8px;
      }
      span{
        font-size: 15px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0;
        text-align: left;
        color: #1F2226;
      }
    }
    p{
      width: 20px;
      height: 20px;
    }
  }
  .residences{
    display: flex;
    flex-direction: column;
    margin-left: 26px;
    .residences-block{
        display: flex;
        align-items: center;
        margin-top: 10px;
        p{
          width: 16px;
          margin-right: 8px;
          margin-bottom: unset;
        }
        span{
          font-size: 13px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: 0.015em;
          text-align: left;
          color: #1F2226;
          margin-bottom: 6px;
        }
    }
  }
}

.region_block_dropdown{
  margin: 16px 0 0 -16px!important;
  width: 310px;
  height: 500px;
  overflow: scroll;
  padding: 16px 0!important;
  @media screen and (max-width: 800px){
    width: unset!important;
  }
}
.home_region_block_dropdown{
  margin: 16px 0 0 -16px!important;
  width: 190px;
  height: 500px;
  overflow: scroll;
  padding: 16px 0!important;
  @media screen and (max-width: 800px){
    width: unset!important;
  }
}

::-webkit-scrollbar{
  display: none!important;
}
.ant-space-item{
  svg{
    width: 42px;
    margin-left: 46px;
  }
}


