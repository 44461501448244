@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/fonts";


.visit-modal {
  width: 660px !important;


  .inputs-block{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left-block{
      width: 46%;
    }
    .right-block{
      width: 46%;

      .error{
        border-color: #ef5151 !important;
      }

      .input-time {
        width: 100%;
        padding: 4px ;

        &.ant-picker-focused {
          border-color: #8D8D8D;
          outline: 0;
          box-shadow: unset;
        }

        .ant-picker-input > input {
          padding: 5px 0;
          border: 0;
        }

        .custom-input-group {
          textarea {
            border-bottom: 0;
          }
        }
      }
    }

    @media screen and (max-width: 700px){
      flex-direction: column;
      .left-block{
        width: 100%;
      }
      .right-block{
        width: 100%;
      }
    }
  }

  &.success {
    .ant-modal-content {
      .ant-modal-close {
        .ant-modal-close-x {
          svg {
            path {
              fill: white;
            }
          }
        }
      }
    }

    .ant-modal-header {
      background-color: #70D06C !important;

      .ant-modal-title {
        color: white !important;
      }
    }
  }

  &.error {
    .ant-modal-content {
      .ant-modal-close {
        .ant-modal-close-x {
          svg {
            path {
              fill: white;
            }
          }
        }
      }
    }

    .ant-modal-header {
      background-color: #F0452A !important;

      .ant-modal-title {
        color: white !important;
      }
    }
  }

  &.confirm-btn-disable {
    .ant-modal-footer {
      button {
        &.ant-btn-primary {
          background-color: #EFEFF0 !important;
        }
      }
    }
  }

  .ant-modal-content {
    border-radius: 0;

    .ant-modal-body {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 38px 36px 0 36px;

      .custom-input-group {
        margin-bottom: 30px;
        width: 100%;
      }
    }


    .ant-modal-header {
      height: 58px;
      padding: 0 36px;
      border-radius: unset;
      background-color: $brandColor;
      border-bottom: none;
      display: flex;
      align-items: center;

      .ant-modal-title {
        color:white;
        font-size: 24px;
        font-weight: 500;
        line-height: 29px;
        letter-spacing: 0px;
        text-align: left;

      }
    }

    .ant-modal-footer {
      border-top: none;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 10px 40px 40px;

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 130px;
        height: 36px;
        font-size: 13px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0.01em;
        text-align: center;
        border: none;
        text-transform: capitalize;
        transition: all 0.5s ease 0s;
        white-space: nowrap;
        color: black;
        box-shadow: none;
        border-radius: unset!important;

        &:first-child {
          background: #F6F3ED !important;
          color: #B79B7A;

        }

        &.ant-btn-primary {
          background-color: #CDBB99;
          color: #FFFFFF;
        }
      }
    }
  }

  @media all and (max-width: 600px) {
    width: calc(100% - 80px) !important;
    .ant-modal-content {
      flex-direction: column;

      .ant-modal-body {

        .row {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;

          .custom-input-group {
            width: 100%;
            margin-bottom: 40px;
          }

          .time-checkbox-wrapper {
            width: 100%;
          }
        }
      }

      .custom-input-group {
        width: 100% !important;
      }

      .ant-modal-footer {
        button {
          height: 40px;
          font-size: 14px;
        }
      }
    }
  }
  @media all and (max-width: 450px) {
    width: calc(100% - 20px) !important;
    .ant-modal-content {
      .ant-modal-body {
        .row {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;

          .custom-input-group {
            width: 100%;
            margin-bottom: 40px;
          }

          .time-checkbox-wrapper {
            width: 100%;
          }
        }
      }

      .ant-modal-footer {
        button {
          padding: 0 15px;
        }
      }
    }
    .ant-modal-title {
      width: 74% !important;
    }
  }
}

.ant-picker-ranges {
  display: none;
}

.popup-input-time {
  .ant-picker-time-panel-column {
    .ant-picker-time-panel-cell-disabled {
      display: none;
    }
    .ant-picker-time-panel-cell{
      .ant-picker-time-panel-cell-inner{
        &:hover{
          background:$brandColor;
        }
      }
    }
  }
}