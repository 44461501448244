@import "../.././assets/styles/mainVariables";
@import "../.././assets/styles/fonts";

.Faq_wrapper{
  width: 100%;
  margin: auto;

  &__header{
    .header{
      &__block{
        &__title{
          width: 18%;
        }
      }
    }
  }

  &__information{
    width:calc(#{$mainContent} - 12%);
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &__title{
      font-size: 24px;
      font-weight: 500;
      line-height: 29px;
      letter-spacing: 0;
      margin-bottom: 60px;
    }

    .ant-collapse{
      background-color: transparent;
      border: unset;
    }
    &__blocks{
      width: 100%;
      background: transparent;
      border: unset;

      &__wrapper{
        border:1px solid #E0E0E0;
        margin-bottom: 40px;
        .ant-collapse-content{
          border-top: unset!important;
        }
        .ant-collapse-header-text{
          width: 92%;
          font-size: 18px;
          font-weight: 400;
          line-height: 27px;
          letter-spacing: 0;
        }
        .ant-collapse-header{
          padding: 20px!important;
        }
      }

      &__description{
        width: 96%;
        font-size: 15px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.02em;
        color: #858585;
        margin-bottom: 20px;
      }
    }
  }

  @media screen and (max-width: 1050px){
    &__header{
      .header{
        &__block{
          &__title{
            width: 26%;
          }
        }
      }
    }

    &__information{
      padding: 20px;
    }
  }

  @media screen and (max-width: 800px){
    &__header{
      .header{
        &__block{
          &__title{
            right: 40%;
            width: 37%;
          }
        }
      }
    }

    &__information{
      width: #{$mainContent};
    }
  }
}