@import "./assets/styles/antd-styles-overrides";
@import "./assets/styles/mainVariables";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body, #root {
  width: 100%;
  height: 100%;
  overflow-y: unset!important;
}

body {
  margin: 0;
  font-family:Montserrat,Gilroy, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 15px;
  //user-select: none;
  background-color: $bgColor;
}

button {
  cursor: pointer;
  border: none;
  padding: 0;
  user-focus: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &:focus {
    outline: none;
  }
}

img {
  user-focus: none;
  user-select: none;
}

p {
  margin-bottom: 0;
}

a, a:hover {
  text-decoration: none;
  color: inherit;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.custom-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10101;
  overflow: hidden;

  .ant-modal {
    width: 100% !important;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 0;
    top: 0;

    .ant-modal-header, .ant-modal-footer, .ant-modal-close {
      display: none;
    }

    .ant-modal-body {
      padding: 0;
    }

    .ant-modal-content {
      position: relative;
      border-radius: 8px;
      background-color: transparent;
      @media screen and (max-width:600px){
        top: 70px;
      }
    }

    .slider-modal-content {
      border-radius: 8px;
      background-repeat: no-repeat;
      background-attachment: fixed;
      background-position: center;

      .dialog-closing-btn {
        top: 80px;
        width: 40px;
        height: 40px;
        opacity: 0;

        svg {
          width: 100%;
          height: 100%;

          polygon {
            fill: white;
          }
        }

        &.show {
          transition: opacity 0.2s;
          opacity: 1;
        }
      }
    }

  }
}

.ant-dropdown-menu-item:hover, .ant-dropdown-menu-submenu-title:hover, .ant-dropdown-menu-item.ant-dropdown-menu-item-active, .ant-dropdown-menu-item.ant-dropdown-menu-submenu-title-active, .ant-dropdown-menu-submenu-title.ant-dropdown-menu-item-active, .ant-dropdown-menu-submenu-title.ant-dropdown-menu-submenu-title-active {
  background-color: transparent !important;

  div {
    color: $brandColor !important;
  }
}

[class*="ground-pane"] {
  //filter: grayscale(1);
}

.social-media{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding: 6px;
  .copied-link{
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: #858585;
    margin: 8px 0;
    svg{
      width: 20px;
      height: 20px;
      margin-right: 6px;
    }
  }
  .copy-share{
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 13px;
    margin: 8px 0;
    svg{
      width: 18px;
      height: 18px;
      margin-right: 6px;
    }
  }
  button{
    p{
      display: flex;
      align-items: center;
      font-size: 13px;
      svg{
        width: 18px;
        height: 18px;
        margin-right: 6px;
      }
    }
    &:last-child{
      svg{
        margin-left: 6px;
      }
    }
  }
}
.lang_menu_item{
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #1F2226;
  margin-bottom: 10px;

  svg{
    width: 16px!important;
    height: 16px!important;
  }
}
.ant-breadcrumb-link{
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.015em;
}

ymaps[class$="_checked"]{
  background-color: white!important;
}

