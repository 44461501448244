@import "../../assets/styles/mainVariables";
@import "../../assets/styles/fonts";

.sign_in {
  padding-bottom: 80px;
  .forgot_pass{
    align-self: flex-end;
    color: $darkColor;
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 30px;
    font-weight: 500;
    &:hover{
      color: $brandColor;
    }
  }
}
