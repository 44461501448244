@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/mixins";

.productCardList-wrapper {

  width: 100%;
  height: 210px;
  margin-bottom: 74px;
  display: flex;
  justify-content: space-between;

  .post_content {
    border-top: 0;
    position: relative;
    width: calc(100% - 240px);

    .content_top {
      display: flex;
      justify-content: space-between;
      align-items: baseline;

      .title_wrapper {
        width: 64%;

        .title {
          color: #1F2226;
          margin-bottom: 15px;
          font-size: 20px;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0.02em;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        .information {
          font-weight: 500;
          font-size: 14px;
          color: black;
          line-height: 16px;
        }
      }

      .price-block {
        width: 42%;
        color: #1F2226;
        letter-spacing: 0.02em;
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-end;
        justify-content: flex-end;

        .price {
          font-size: 20px;
          font-weight: 600;
          line-height: 18px;
          letter-spacing: 0.02em;
          text-align: left;
          margin-bottom: 4px;
          color: #1F2226;

          .contractual {
            font-size: 16px;
            font-weight: 600;
            line-height: 18px;
            letter-spacing: 0.02em;
            text-align: left;
            color: #1F2226;

            span {
              font-size: 14px;
              font-weight: 600;
              line-height: 18px;
              letter-spacing: 0em;
              text-align: left;
              color: #1F2226;
            }
          }
        }

        .rentPrice {
          font-size: 18px;
          font-weight: 400;
          line-height: 32px;
          letter-spacing: 0em;
          text-align: left;
          color: #1F2226;

          .rent {
            font-size: 15px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0em;
            text-align: left;
            color: #1F2226;
          }

          .contractual {
            font-size: 18px;
            font-weight: 400;
            line-height: 32px;
            letter-spacing: 0em;
            text-align: left;
            color: #1F2226;

            span {
              font-size: 15px;
              font-weight: 400;
              line-height: 18px;
              letter-spacing: 0em;
              text-align: left;
              color: #1F2226;
            }
          }
        }

        .price_for {
          font-weight: 500;
          font-size: 13px;
          line-height: 14px;
          text-align: right;
        }
      }

      .price_wrapper {
        width: 42%;
        color: #1F2226;
        letter-spacing: 0.02em;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;

        .price {
          font-size: 20px;
          font-weight: 600;
          line-height: 18px;
          letter-spacing: 0.02em;
          text-align: left;
          margin-bottom: 4px;
          color: #1F2226;

          .contractual {
            font-size: 16px;
            font-weight: 600;
            line-height: 18px;
            letter-spacing: 0.02em;
            text-align: left;
            color: #1F2226;

            span {
              font-size: 14px;
              font-weight: 600;
              line-height: 18px;
              letter-spacing: 0em;
              text-align: left;
              color: #1F2226;
            }
          }
        }

        .rentPrice {
          font-size: 18px;
          font-weight: 400;
          line-height: 32px;
          letter-spacing: 0em;
          text-align: left;
          color: #1F2226;

          .rent {
            font-size: 15px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0em;
            text-align: left;
            color: #1F2226;
          }

          .contractual {
            font-size: 18px;
            font-weight: 400;
            line-height: 32px;
            letter-spacing: 0em;
            text-align: left;
            color: #1F2226;

            span {
              font-size: 15px;
              font-weight: 400;
              line-height: 18px;
              letter-spacing: 0em;
              text-align: left;
              color: #1F2226;
            }
          }
        }

        .price_for {
          font-weight: 500;
          font-size: 13px;
          line-height: 14px;
          text-align: right;
        }
      }

    }

    .post_description {
      margin: 25px 0 15px 0;
      overflow: hidden;
      height: 36px;
      font-size: 13px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0.015em;
      color: #1F2226;
    }

    .post_address {
      display: flex;
      align-items: center;
      color: #858585;
      margin-bottom: 12px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      height: 16px;
      font-size: 13px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0.015em;

      svg {
        width: 10px;
        height: 11px;
        margin-right: 4px;
      }
    }

    .post_actions {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .right_part {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 70%;
        max-width: 340px;

        .phone {
          display: none;
        }

        .priceBlock {
          display: none;
        }

        .mobile-price {
          display: none;
        }

        .price-block {
          display: none;
        }

        .price_wrapper {
          display: none;
        }

        .favorite_btn {
          width: 90px;
          background: transparent;
          border: 0;
          display: flex;
          align-items: center;
          color: #1F2226;

          span {
            width: 86%;
          }
        }

        .phone_btn_mob {
          display: none;
        }
      }

      .date {
        white-space: nowrap;
        font-size: 12px;
        font-weight: 500;
        line-height: 15px;
        color: #858585;
      }
    }

    .line {
      width: 100%;
      height: 1px;
      background: #E0E0E0;
      transform: translateY(34px);
    }
  }

  &:hover {
    .post_content {
      .content_top {
        .title_wrapper {
          .title {
            cursor: pointer;
            color: #CDBB99;
          }
        }
      }
    }
  }

  @media all and (max-width: 1300px) {
    height: 190px;
    .post_content {
      width: calc(100% - 200px);

      .content_top {
        .title_wrapper {
          .title {
            font-size: 18px;
          }
        }

        .price_wrapper {
          .price {
            font-size: 18px;
          }
        }
      }

      .post_description {
        margin-top: 12px;
      }

      .post_actions {

        .right_part {
          .favorite_btn {
            width: unset;

            span {
              display: none;
            }
          }
        }
      }
    }
  }

  @media all and (max-width: 1050px) {
    margin-bottom: 56px;
    height: 150px;
    .post_content {
      width: calc(100% - 170px);

      .content_top {
        .title_wrapper {
          width: 100%;

          .title {
            height: 26px;
            overflow: hidden;
            font-size: 18px;
          }
        }

        .price-block {
          display: none;
        }

        .price_wrapper {
          display: none;
        }
      }

      .post_description {
        display: none;
      }

      .post_address {
        margin-top: 10px;
        margin-bottom: 45px;
      }

      .post_actions {

        .date {
          display: none;
        }

        .right_part {
          width: 100%;
          max-width: unset;

          .compare {
            display: none;
          }

          .share-icon {
            display: none;
          }

          .priceBlock {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            display: flex;
            flex-direction: column-reverse;
            justify-content: flex-start;
            align-items: flex-start;
            font-size: 15px;
            font-weight: 600;
            line-height: 18px;
            letter-spacing: 0.02em;
            text-align: right;
          }

          .mobile-price {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            font-size: 15px;
            font-weight: 600;
            line-height: 18px;
            letter-spacing: 0.02em;
            text-align: right;
            color: #1F2226;

            .rentPrice {
              font-size: 14px;
              font-weight: 400;

              .rent {
                font-size: 13px;
                font-weight: 400;
              }

              .contractual {
                font-size: 12px;
                font-weight: 400;

                span {
                  font-size: 10px;
                  font-weight: 400;
                }
              }
            }
          }


          .phone {
            display: flex;
            width: 34px;
            height: 34px;
            background-color: #F6F3ED;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            margin: 0 10px;

            a {
              button {
                border-radius: unset;

                &:hover {
                  svg {
                    path {
                      fill: white;
                    }
                  }
                }
              }
            }
          }

          .price_wrapper {
            display: block;
            color: #1F2226;
            letter-spacing: 0.02em;

            .price {
              font-size: 18px;
              line-height: 16px;
              font-weight: 900;
              margin-bottom: 10px;
            }

            .price_for {
              font-weight: 500;
              font-size: 13px;
              line-height: 14px;
              text-align: left;
            }
          }

          .action_icon, .favorite_btn {
            display: none;
          }

          .phone_btn {
            display: none;
          }

          .phone_btn_mob {
            width: 34px;
            height: 34px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;

            svg {
              margin: 0;

              path {
                fill: #BCA477;
              }
            }
          }
        }
      }

      .line {
        transform: translateY(24px);
      }
    }
  }

  @media all and (max-width: 450px) {

    .post_content {
      width: calc(100% - 140px);

      .post_address {
        margin-bottom: 10px;
      }
    }
  }

}