@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/mixins";

.compare-card{
  width: 240px;
  z-index: 10;
  border: 1px solid #F4F0EB;
  border-right: unset;

  :hover{
    .product_title{
      color: $brandColor;
    }
  }

  &:first-child{
    border-left: 1px solid white;
  }
  .cxHUbL{
    height: unset!important;
  }
  .content_description{
    .inform_block{
      .price{
        div{
          font-size: 15px;
          font-weight: 600;
          line-height: 18px;
          letter-spacing: 0em;
          span{
            font-size: 13px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0.015em;
            margin-left: 6px;
          }
        }
      }
    }
  }
  .clear_icon{
    width: 240px;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    padding: 4px;
    svg{
      width: 20px;
      height: 20px;
    }
  }
  .product_content {
    padding: 0 16px 16px 16px;
    position: relative;
    transition: all .3s;
    background-color: white;
    width: 100%;
    border-bottom: 1px solid #F4F0EB;

    .product_date {
      font-size: 12px;
      line-height: 18px;
      color: #C1C1C1;
      margin: 10px 0 6px 0;
    }

    .product_title {
      font-size: 15px;
      line-height: 18px;
      letter-spacing: 0.01em;
      font-weight: 700;
      margin: 16px 0 7px 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      height: 18px;
    }

    .product_address {
      display: flex;
      align-items: center;
      color:#858585;
      margin-bottom: 29px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      height: 16px;
      font-size: 13px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0.015em;
      svg{
        margin-right: 4px;
      }
    }

    .price {
      font-size: 20px;
      line-height: 16px;
      font-weight: 900;
      color: #1F2226;
      letter-spacing: 0.02em;
      margin-bottom: 8px;
    }

    .price_for {
      font-weight: 500;
      font-size: 13px;
      line-height: 14px;
      color: #1F2226;
      letter-spacing: 0.02em;
      margin-bottom: 12px;
    }

    .call_btn {
      position: relative;
      overflow: hidden;
      height: 40px;
      width: 100%;
    }

    @media (max-width: 1280px) {
      //padding: 8px;
    }

    @media (max-width: 1050px) {
      //padding: 8px;

      .call_btn {
        opacity: 1;
        height: 40px;
      }
    }
  }
  .content_description{
    height: 266px;
    .inform_block{
      border-bottom: 1px solid #F4F0EB;
      padding: 16px;
      font-size: 15px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.02em;
      color: #1F2226;
      &:nth-child(even){
        background-color: #FBFAF9;
      }
      &:last-child{
        border-bottom: unset;
      }
    }
  }

  @media screen and (max-width: 400px){
    width: 160px;

    .clear_icon{
      width: 160px;
    }
  }
}
