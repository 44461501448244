@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/mixins";

.map, .map-section {
  width: 100%;
  height: 100%;
  //height: 600px;
  position: relative;

  .icon-content {
    background-color: $brandColor;
    color: white;
    font-weight: 900;
    padding: 5px 18px;
    width: fit-content;
  }

  .placemark_layout_container {
    position: relative;
  }
}

.ballon {
  position: relative;
  //border: 1px solid #cdbb99;


  .placemark {
    background-color: $brandColor;
    color: white;
    width: 100%;
    height: 100%;
    //width: 100px!important;
    //height: 30px!important;
    display: flex;
    align-items: center;
    padding: 0 5px;
    font-size: 13px;
    font-weight: 600;
    justify-content: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .content {
    display: none;
    position: absolute;
    bottom: 50px;
    z-index: 701;
    width: 378px;
    height: 130px;
    background-color: white;

    .image {
      background-color: #CDBB99;
      width: 130px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .info {
      width: calc(100% - 130px);
      display: flex;
      flex-direction: column;
      padding: 14px;

      .title {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #1F2226;
        font-size: 15px;
        font-weight: 600;
        line-height: 18px;
        margin-bottom: 2px;
      }

      .description {
        margin-bottom: 15px;
        font-size: 12px;
        font-weight: 300;
        line-height: 15px;
      }

      .post_address {
        display: flex;
        align-items: center;
        color: #858585;
        margin-bottom: 14px;
        font-size: 13px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0.015em;

        svg{
          margin-right: 4px;
        }
      }

      .bottom-part {
        display: flex;
        justify-content: space-between;
        align-items:center;

          .price {
            font-size: 15px;
            font-weight: 600;
            line-height: 18px;
            div{
              font-size: 15px;
              font-weight: 600;
              line-height: 18px;
              letter-spacing: 0em;
              span{
                font-size: 13px;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: 0.015em;
                margin-left: 6px;
              }
            }
          }
      }
    }
  }


  &.selected {
    .content {
      display: flex;
    }
  }
}
