@import "../../assets/styles/mainVariables";
@import "../../assets/styles/fonts";

.password-form {
  .title{
    line-height: 30px;
    margin-bottom: 16px;
  }
  .text{
    margin-bottom: 60px;
  }
}
