@import "../../assets/styles/mainVariables";
@import "../../assets/styles/fonts";

.sign_up {
  padding-bottom: 80px;
  .date_wrapper {
    width: 100%;

    label {
      display: block;
      color: $greyTextColor;
      margin-bottom: 14px;
      //styleName: H4/Regular/15px;
      font-size: 15px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.02em;
    }

    .inputs {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .custom-input-group {
        width: 30%;
      }
    }

  }

  .warning-text{
    display: flex;
    align-self: flex-start;
    font-family:Regular;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.015em;
    color: #858585;
    position: relative;
    top: -12px;
  }
  .error{
    color: red;
  }

  .accept-privacy-policy {
    margin: 90px 0 20px 0;
    display: flex;
    color: $brandColor;
    align-self: flex-start;

    .privacy-policy-checkbox {
      &.error .ant-checkbox-inner {
        border-color: red !important;
      }
    }

    .privacy-policy-link {
      margin-left: 10px;
      color: $brandColor;
    }
  }
}
