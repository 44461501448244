@import "../../../assets/styles/fonts";
@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/mixins";


$light-grey: #c9c6c6;

.slider-modal-wrapper {
  width: 100vw;
  height: 100vh;
  ::-webkit-scrollbar{
    display: none;
  }

  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #F8F5F2;
    //border-bottom: 1px solid #E0E0E0;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: $darkColor;
    height: 110px;
    padding: 0 26px;
    .modal-title{
      width: 180px;
      height: 110px;
      margin-left: 20px;
      svg{
        width: 100%;
        height: 100%;
      }
    }
  }

  .slider-wrapper {
    width: 100vw;
    height: 93vh;
    background-color: white;
    display: flex;

    .images-list {
      width: 272px;
      position: relative;
      height: 100%;
      //border-left: 1px solid $greyColor;
      padding-top: 30px;
      background-color: #F8F5F2;

      .top-border{
        width: 100vw;
        position: absolute;
        top: -14px;
      }
      .right-border{
        height: 91vh;
        position: absolute;
        right: 0;
        top: 0;
        svg{
          height: 100%;
        }
      }

      .respons-top-border{
        display: none;
      }

      .list-title {
        padding: 0 26px;
        color: $brandColor;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 20px;
      }

      .list-wrapper {
        height: 74%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 0 26px;
        overflow: auto;
        max-height: 694px;
        @include custom-scrollbar-y;

        .list-img {
          cursor: pointer;
          top: 0;
          left: 0;
          width: 100px;
          height: 100px;
          object-fit: contain;
          background-color: white;
          margin-bottom: 16px;

          &.active {
            border: 2px solid $brandColor;
          }
        }
      }
      .contact-block{
        width: 100%;
        border-top: 1px solid #E0E0E0;
        display: flex;
        justify-content: start;
        flex-direction: column;
        padding: 30px 0 0 40px;
        height: 90px;
        .contact-dropDown{
          margin-bottom: 12px;
          height: 20px;
          font-size: 13px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: 0.015em;
          text-align: left;
          svg{
            margin-right: 8px;
            width: 22px;
            height: 22px;
          }
        }
        .contact_item{
          height: 20px;
          font-size: 13px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: 0.015em;
          text-align: left;
          color: black;
          display: flex;
          align-items: center;
          svg{
            margin-right: 8px;
            width: 22px;
            height: 22px;
          }
        }
      }
    }

    .main-img-wrapper {
      width: calc(100% - 272px);
      position: relative;
      overflow: hidden;
      background-color: #F8F5F2;
      //border-left: 1px solid #E0E0E0;

      .img-top-border{
        width: 100vw;
        position: absolute;
        top: -14px;
      }
      .zoom-wrapper{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;


        .tools{
          position: absolute;
          right: 20px;
          bottom: 100px;
          z-index: 1000;
          display: flex;
          align-items: center;
          flex-direction: column;
          border: 1px solid #B79B7A;
          background-color: white;
          width: 30px;
          button{
            background-color: white;
            display: flex;
            align-items: center;
          }
          .zoom-in{
            margin: 8px 0;
          }
          .zoom-out{
            padding: 14px 0;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border-top: 1px solid #B79B7A;
          }
        }
        .main-img-content {
          position: relative;
          width:840px;
          height: 840px;
          margin: auto;

          .main-img {
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
          }
        }
      }

      .slider-btn {
        width: 32px;
        height: 64px;
        background: #E0E0E0;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 50%;
        transform: translateY(-50%);
        position: absolute;
        z-index: 1;

        svg {
          height: 16px;

          path {
            stroke: #858585;
            transition: all 0.2s;
          }
        }

        &.prev-btn {
          left: 10px;

          svg {
            transform: rotate(180deg);
          }
        }

        &.next-btn {
          right: 10px;
        }
      }
    }
  }

  .dialog-closing-btn {
    background-color: transparent;
    display: flex;
    align-items: center;

    img, svg {
      width: 32px;

      polygon {
        fill: #202020;
      }
    }
  }
}

@media all and (max-width: 1600px) {
  .slider-modal-wrapper {

    .slider-wrapper {
      .images-list {

        .list-wrapper {
          max-height: 504px;
        }
      }

      .main-img-wrapper {
        .zoom-wrapper{
          .main-img-content {
            width: 840px;
            height: 840px;
          }
        }
      }
    }
  }
}

@media all and (max-height: 880px) {
  .slider-modal-wrapper {

    .slider-wrapper {
      .images-list {

        .list-wrapper {
          max-height: 504px;
        }
      }

      .main-img-wrapper {
        .zoom-wrapper{
          .main-img-content {
            width: 700px;
            height: 700px;
          }
        }
      }
    }
  }
}

@media all and (max-height: 700px) {
  .slider-modal-wrapper {
    .modal-header {
      font-size: 20px;
      line-height: 24px;
    }

    .slider-wrapper {

      .main-img-wrapper {
        .zoom-wrapper{
          .main-img-content {
            width: 630px;
            height: 630px;
          }
        }
      }
    }
  }
}

@media all and (max-width: 1050px) {
  .slider-modal-wrapper {

    .slider-wrapper {
      flex-direction: column-reverse;
      //height: unset!important;

      .images-list {
        width: 100%;
        padding: 0 26px;

        .top-border{
          display: none;
        }
        .right-border{
         display: none;
        }
        .respons-top-border{
          display: flex;
          position: absolute;
          top: 0;
        }

        .list-wrapper {
          max-height: 120px;
          flex-wrap: nowrap;
          justify-content: unset;
          @include custom-scrollbar-x;

          .list-img {
            margin-right: 20px;
          }
        }
      }

      .main-img-wrapper {
        width: 100%;
        margin: 0;
        .zoom-wrapper{
          .main-img-content {
            width: 100%;
            height: 500px;
            .main-img{
              height: 70%;
              top: unset;
              transform: unset;
              -webkit-transition: unset;
            }
          }
        }
      }
    }
  }
}

@media all and (max-width: 600px) {
  .slider-modal-wrapper {
    height: unset;
    overflow: scroll;
    //margin-top: 106px;


    .modal-header {
      font-size: 18px;
      line-height: 20px;
      width: 100vw;
      padding: 10px 26px;
      .modal-title{
        //height: 44px;
      }
    }

    .slider-wrapper {
      flex-direction: column-reverse;
      height: unset;

      .images-list {
        width: 100%;
        padding: 0 $mainPadding;
        //height: 44vh;

        .respons-top-border{
          svg{
            width: 96vw;
          }
        }

        .list-title {
          padding: 0;
          font-size: 14px;
        }

        .list-wrapper {
          max-height: 100px;
          flex-wrap: nowrap;
          padding: 0;
          @include custom-scrollbar-x;

          .list-img {
            margin-right: 20px;
            width: 80px;
            height: 80px;
          }
        }
      }

      .main-img-wrapper {
        width: 100%;
        margin: 0;

        .zoom-wrapper{
          .main-img-content {
            width: 100%;
            height: 504px;
          }
        }

        .slider-btn {
          &.prev-btn {
            left: 3px;
          }

          &.next-btn {
            right: 3px;
          }
        }
      }
    }
  }
}


.ant-modal-content {
  box-shadow: unset !important;
}
