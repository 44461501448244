@import '../../../assets/styles/mainVariables';
@import '../../../assets/styles/fonts';

.clock_item {
  width: 80px;
  display: flex;
  flex-direction: column;
  margin-right: 35px;

  &__content {
    width: 68px;
    height: 68px;
    position: relative;
    margin: auto;

    &__center {
      height: 10px;
      width: 10px;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 11
    }

    .hand {
      position: absolute;
      width: 30px;
      height: 40px;
      bottom: 50%;
      left: 50%;
      transform-origin: bottom;
      z-index: 10;
    }

    .hand.second {
      width: 1%;
      height: 38%;
      background-color: #7a0606;
      box-shadow: 0 0.5px 1px 0 #2B2B2BA6;
    }

    .hand.minute {
      width: 2%;
      height: 38%;
      background-color: black;
      box-shadow: 0 0.5px 1px 0 #2B2B2BA6;
    }

    .hand.hour {
      width: 3%;
      height: 28%;
      background-color: black;
      box-shadow: 0 0.5px 1px 0 #2B2B2BA6;
    }
  }

  .city {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #1F2226;
    font-size: 10px;
    line-height: 18px;
    letter-spacing: 0.02em;
    text-align: left;
    margin-top: 10px;
  }
}

.clocks-block {
  margin-left: 280px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 1600px){
    margin-left: 160px;
  }
}

@media screen and (max-width: 1300px){
  .clock_item {
    margin-right: 20px;
  }
  .clocks-block {
    margin-left: 103px;
  }
}
@media screen and (max-width: 700px){
  .clock_item {
    width: 25%;
    margin-right: 0;
    text-align: center;
  }
  .clocks-block {
   width: 100%;
    margin-top: 30px;
    margin-left: 0;
    justify-content: space-between;
  }
}
