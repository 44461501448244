@import "../../assets/styles/mainVariables";
@import "../../assets/styles/fonts";

.post-details {
  width: $mainContent;
  margin: auto;

  &__header {
    width: 100%;
    height: 55px;
    border-top: 1px solid $borderColor;
    border-bottom: 1px solid $borderColor;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;

    &__actions {
      display: flex;

      .action_btn {
        margin-left: 10px;
        cursor: pointer;
      }
    }
  }

  .content {
    display: flex;
    justify-content: space-between;

    &__left {
      width: 56%;
      position: relative;

      .mobile-block-wrapper{
        display: none;
        .mobile-header{
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          margin-bottom: 16px;

          .badges {
            display: flex;
            align-items: flex-start;

            .urgent {
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: #b79b7a;
              color: white;
              height: 36px;
              padding: 0 6px;
              text-overflow: ellipsis;
              overflow: hidden;
              margin-right: 10px;
              position: unset;
            }

            .top {
              height: 36px;
              min-width: 36px;
              padding: 0 6px;
              background-color: #cdbb99;
              color: white;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 12px;
              margin-right: 10px;
            }
          }

          .header_right {
            display: flex;
            align-items: center;

            .updated {
              font-size: 12px;
              line-height: 134.9%;
              letter-spacing: 0.015em;
              color: black;
              font-weight: 700;

              span {
                color: black;
                font-weight: 700;
              }
            }

            .id {
              font-weight: 500;
              font-size: 18px;
              line-height: 22px;
              color: $darkColor;
              margin-left: 13px;
            }
          }

          @media all and (max-width: 500px) {
            .header_right {
              margin-top: 10px;
            }
          }
        }

        .post_title {
          font-weight: 500;
          font-size: 24px;
          line-height: 29px;
          margin-bottom: 20px;
          color: $darkColor;
        }
        .prices-block{
          display: flex;
          flex-direction: column-reverse;
        }
        .prices-block{
          display: flex;
          flex-direction: column-reverse;
        }
        .post_price {
          font-size: 20px;
          font-weight: 600;
          line-height: 39px;
          letter-spacing: 0;
          text-align: left;
          margin-bottom: 4px;
          display: flex;
          align-items: center;
          color: #1F2226;

          .rent {
            font-size: 18px;
            font-weight: 400;
            line-height: 22px;
            color: #1F2226;
            margin-bottom: 4px;
          }
          .contractual{
            font-size: 20px;
            font-weight: 600;
            line-height: 39px;
            letter-spacing: 0;
            text-align: left;
            color: #1F2226;
            span{
              font-size: 18px;
              font-weight: 600;
              line-height: 32px;
              letter-spacing: 0;
              text-align: left;
              color: #1F2226;
            }
          }

        }

        .price{
          font-size: 20px;
          font-weight: 400;
          line-height: 29px;
          letter-spacing: 0;
          text-align: left;
          color: #1F2226;

          .contractual{
            font-size: 20px;
            font-weight: 400;
            line-height: 29px;
            letter-spacing: 0;
            text-align: left;
            color: #1F2226;
            span{
              font-size: 18px;
              font-weight: 400;
              line-height: 32px;
              letter-spacing: 0;
              text-align: left;
              color: #1F2226;
            }
          }
          .rent{
            font-size: 16px;
            font-weight: 400;
            line-height: 32px;
            letter-spacing: 0;
            text-align: left;
            color: #1F2226;
          }
        }

        .post_address {
          cursor: pointer;
          font-weight: 400;
          font-size: 18px;
          line-height: 22px;
          color: $darkColor;
          text-decoration-line: underline;
          margin-bottom: 12px;
        }

        .post_numbers {
          width: 100%;

          &:hover {
            background-color: #B79B7A;
          }
        }
      }

      .mobile-details-wrapper{
        display: none;

        .details {
          font-weight: 500;
          font-size: 24px;
          line-height: 29px;
          margin-bottom: 40px;
          margin-top: 20px;
        }

        .details_content {
          width: 100%;
          display: flex;
          justify-content: space-between;
          margin-bottom: 36px;

          .item {
            width: 30%;
            display: flex;
            flex-direction: column;
            color: $darkColor;
            margin-right: 6px;

            &:not(:first-child) {
              padding: 0 8px;
              border-left: 1px solid $borderColor;
            }

            &_title {
              font-weight: 500;
              font-size: 24px;
              line-height: 29px;
              margin-bottom: 10px;
              white-space: nowrap;
              position: relative;

              span {
                position: absolute;
                top: -4px;
                font-size: 12px;
              }
            }


            &_desc {
              font-weight: 400;
              font-size: 15px;
              line-height: 135.9%;
              letter-spacing: 0.02em;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }

      .urgent {
        position: absolute;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #E34256;
        color: white;
        height: 40px;
        width: 80px;
        top: 0;
        left: 0;
        max-width: 100px;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      &__details {
        margin-bottom: 20px;
      }

      &__advantages {
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        color: $darkColor;
        margin-bottom: 30px;
      }

      &__advantages_list {
        display: flex;
        flex-wrap: wrap;

        .list_item {
          display: flex;
          align-items: center;
          width: calc(100% / 3 - 40px);
          height: 48px;
          border: 1px solid $borderColor;
          margin-bottom: 20px;
          padding: 0 10px;

          &:not(:nth-child(3)) {
            margin-right: 20px;
          }

          img {
            width: 24px;
            height: 24px;
          }

          span {
            padding-left: 10px;
            flex: 1;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
    }

    &__right {
      width: 40%;

      &__info {
        .post_info_header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 16px;

          .badges {
            display: flex;
            align-items: flex-start;

            .urgent {
              display: flex;
              align-items: center;
              justify-content: center;
              background-color:#B79B7A;
              color: white;
              height: 36px;
              padding: 0 6px;
              text-overflow: ellipsis;
              overflow: hidden;
              margin-right: 10px;
            }

            .new_badgs{
              width: unset;
              padding: 0 17px;
            }

            .top {
              height: 36px;
              min-width: 36px;
              padding: 0 17px;
              background-color: #CDBB99;
              color: white;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 12px;
              margin-right: 10px;
            }
          }

          .header_right {
            display: flex;
            align-items: center;
            width: 48%;

            .updated {
              width: 54%;
              font-size: 12px;
              line-height: 134.9%;
              letter-spacing: 0.015em;
              color: black;
              font-weight: 700;

              span {
                color:black;
                font-weight: 700;
              }
            }

            .id {
              width: 52%;
              font-weight: 500;
              font-size: 18px;
              line-height: 22px;
              color: $darkColor;
              margin-left: 13px;
            }
          }

          @media all and (max-width: 500px) {
            .header_right {
              align-self: flex-end;
              margin-top: 10px;
            }
          }
        }

        .post_title {
          font-weight: 500;
          font-size: 24px;
          line-height: 29px;
          margin-bottom: 20px;
          color: $darkColor;
        }

        .prices-block{
          display: flex;
          flex-direction: column-reverse;
        }

        .post_price {
          font-size: 32px;
          font-weight: 600;
          line-height: 39px;
          letter-spacing: 0;
          text-align: left;
          margin-bottom: 8px;
          display: flex;
          align-items: center;
          color: #1F2226;

          .rent {
            font-size: 22px;
            font-weight: 400;
            line-height: 22px;
            color: #1F2226;
            margin-bottom: 4px;
          }

          .contractual{
            font-size: 30px;
            font-weight: 600;
            line-height: 39px;
            letter-spacing: 0;
            text-align: left;
            color: #1F2226;
            span{
              font-size: 26px;
              font-weight: 600;
              line-height: 32px;
              letter-spacing: 0;
              text-align: left;
              color: #1F2226;
            }
          }
        }

        .price{
          font-size: 24px;
          font-weight: 400;
          line-height: 29px;
          letter-spacing: 0;
          text-align: left;
          color: #1F2226;
          margin-bottom: 8px;

          .rent{
            font-size: 20px;
            font-weight: 400;
            line-height: 32px;
            letter-spacing: 0;
            text-align: left;
            color: #1F2226;
          }
        }

        .post_address {
          cursor: pointer;
          font-weight: 400;
          font-size: 18px;
          line-height: 22px;
          color: $darkColor;
          text-decoration-line: underline;
          margin-bottom: 44px;
        }

        .post_numbers {
          width: 100%;
          font-size: 15px;
          font-weight: 500;

          &:hover {
            background-color: #B79B7A;
          }
        }

        .details {
          font-weight: 500;
          font-size: 24px;
          line-height: 29px;
          margin-bottom: 40px;
          margin-top: 60px;
        }

        .details_content {
          display: flex;
          justify-content: space-between;
          margin-bottom: 66px;

          .item {
            width: 32%;
            display: flex;
            flex-direction: column;
            color: $darkColor;
            margin-right: 6px;

            &:not(:first-child) {
              padding: 0 20px;
              border-left: 1px solid $borderColor;
            }

            &_title {
              font-weight: 500;
              font-size: 24px;
              line-height: 29px;
              margin-bottom: 10px;
              white-space: nowrap;
              position: relative;

              span {
                position: absolute;
                top: -4px;
                font-size: 12px;
              }
            }
            &_number{
              font-weight: 500;
              font-size: 24px;
              line-height: 29px;
              margin-bottom: 10px;
              white-space: nowrap;
              position: relative;
              margin-left: 6px;
            }

            &_desc {
              font-weight: 400;
              font-size: 15px;
              line-height: 135.9%;
              letter-spacing: 0.02em;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }

      .mobile-advantages-wrapper{
        display: none;

        .advantages_title{
          font-weight: 500;
          font-size: 24px;
          line-height: 29px;
          color: $darkColor;
          margin-bottom: 30px;
        }

        .advantages_list {
          display: flex;
          flex-wrap: wrap;

          .list_item {
            display: flex;
            align-items: center;
            width: calc(100% / 3 - 40px);
            height: 48px;
            border: 1px solid $borderColor;
            margin-bottom: 20px;
            padding: 0 10px;

            &:not(:nth-child(3)) {
              margin-right: 20px;
            }

            img {
              width: 24px;
              height: 24px;
            }

            span {
              padding-left: 10px;
              flex: 1;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
        }
      }

      &__location {
        &__title {
          font-weight: 500;
          font-size: 24px;
          line-height: 29px;
          color: $darkColor;
          margin-bottom: 43px;
        }

        &__map {
          width: 100%;
          height: 220px;
          margin-bottom: 45px;
        }
      }

      &__categories {
        &__item {
          display: flex;
          justify-content: space-between;
          align-items:center;
          margin-bottom: 20px;

          .key {
            font-weight: 500;
            font-size: 17px;
            line-height: 135.9%;
            letter-spacing: 0.02em;
          }

          .line {
            border-bottom: 1px dashed #C1C1C1;
            flex-grow: 1;
          }

          .value {
            color: $brandColor;
            margin: 0 3px;
            font-weight: 500;
            font-size: 17px;
            line-height: 135.9%;
            letter-spacing: 0.02em;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .dot {
            color: $brandColor;
          }
        }
      }
    }
  }

  @media all and (max-width: 1300px) {
    .content {
      &__right {
        &__info {
          .details_content {

            .item {
              &_title {
                font-weight: 500;
                font-size: 20px;
                line-height: 24px;
              }

              &_desc {
                font-size: 13px;
              }
            }
          }
        }
      }
    }
  }

  @media all and (max-width: 1050px) {
    padding: unset;

    &__header {
      padding: 0 20px;
    }
    .content {
      padding: 0 20px;
      flex-direction: column;

      &__left {
        width: 100%;

        .mobile-block-wrapper{
          display: flex;
          flex-direction: column;
          margin-bottom: 30px;
        }
        .mobile-details-wrapper{
          display: flex;
          flex-direction: column;
          width: 82%;
        }
        &__advantages {
          display: none;
        }
        &__advantages_list {
          display: none;
        }
      }

      &__right {
        width: 100%;

        &__info{
          display: none;
        }

          .mobile-advantages-wrapper{
            display: flex;
            flex-direction: column;
            margin: 40px 0;
            .advantages_list{
              width: 100%;
              justify-content: space-between;

              .list_item {
                margin-right: unset;
                width: calc(100% / 2 - 20px);

                &:not(:nth-child(3)) {
                  margin-right: unset;
                }
              }
            }
          }
      }
    }
  }
}
