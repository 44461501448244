@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/fonts";
@import "../../../assets/styles/mixins";

.filter {
  width: 390px;
  background: #F8F5F2;
  display: flex;
  flex-direction: column;
  padding: 40px 32px;
  min-height: 100vh;

  .custom-input-group {
    margin-bottom: 30px;

    label {
      font-size: 15px;
      font-weight: 500;
      line-height: 18px;
    }
  }

  &__search {
    display: flex;
    width: 100%;
    margin-bottom: 30px;

    .custom-input-group {
      width: calc(100% - 46px);
      margin-bottom: 0;
      background-color: white;

      .search_input {
        padding: 8px 15px;
      }
    }

    &__btn {
      width: 46px;
      background-color: $brandColor;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: #B79B7A;
      }

      svg {
        path {
          fill: white;
        }
      }
    }
  }

  .switch_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 64px;

    .switch {
      display: flex;
      border: 1px solid $borderColor;
      border-radius: 2px;
      height: 42px;
      width: 180px;
      overflow: hidden;

      span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
        height: 100%;
        cursor: pointer;
        background-color: white;
        transition: all 0.2s;

        &:first-child {
          border-right: 1px solid $borderColor;
        }

        &.active, &:hover {
          background-color: $brandColor;
          color: white;
        }
      }
    }

    .clear_btn {
      display: flex;
      color: #858585;
      cursor: pointer;
      font-size: 14px;

      svg {
        margin-right: 10px;
      }
    }
  }

  .filter_region {
    .filter_region_title {
      font-size: 15px;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: 0;
      text-align: left;
      color: #1F2226;
      margin-bottom: 16px;
    }

    .region_block {
      width: 100%;
      height: 42px;
      border: 1px solid #E0E0E0;
      padding: 9px 0 9px 15px;
      margin-bottom:30px ;
      background-color: white;

      .ant-space-item{
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        display: flex;
        width: 266px;
        margin-bottom: 8px;
        .region-input-text{
          width: 100%;
          font-size: 15px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0.02em;
          text-align: left;
          color: #858585;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
      }

      .ant-dropdown-trigger {
        display: flex;

        .ant-space-align-center {
          display: flex;
          justify-content: space-between;
          align-items: center;

        }
      }
    }
  }

  &__select {
    width: 100%;
    display: flex;
    flex-direction: column;

    .label {
      color: #32373F;
      margin-bottom: 16px;
      font-size: 15px;
      font-weight: 500;
      line-height: 18px;
    }
  }

  &__slider {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    .label {
      color: #32373F;
      font-size: 15px;
      line-height: 18px;
      font-weight: 500;
      margin-bottom: 16px;
    }

    .slider_content {
      width: 100%;
      border: 1px solid $borderColor;
      height: 46px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-radius: 2px;
      background-color: white;

      .slider_label {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 9px 15px;

        span {
          color: #858585;
          margin-right: 6px;
        }
      }

      .ant-slider {
        width: 90%;
        margin: 0 auto;
        height: unset;
        padding: 0;

        .ant-slider-rail {
          background-color: transparent;
        }

        .ant-slider-track {
          background-color: $brandColor;
          height: 2px;
        }

        .ant-slider-handle {
          border: 0;
          background-color: $brandColor;
          width: 10px;
          height: 10px;
          margin-top: -4px;
        }

        .ant-slider-handle-dragging.ant-slider-handle-dragging.ant-slider-handle-dragging {
          box-shadow: 0 0 0 5px rgba(47, 47, 47, 0.1);
          border-color: $borderColor;
        }

        .ant-slider-handle:focus {
          box-shadow: 0 0 0 5px rgba(47, 47, 47, 0.1)
        }

        &:hover {
          .ant-slider-track {
            background-color: $brandColor;
          }

          .ant-slider-rail {
            background-color: transparent;
          }
        }
      }
    }
  }

  &__rooms {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    .label {
      color: #32373F;
      font-size: 15px;
      line-height: 18px;
      font-weight: 500;
      margin-bottom: 16px;
    }

    &__list {
      display: flex;
      border: 1px solid $borderColor;
      background-color: white;
      height: 46px;

      .room {
        width: 25%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid $borderColor;
        cursor: pointer;
        font-size: 15px;
        line-height: 18px;
        letter-spacing: 0.02em;
        color: #858585;
        transition: all 0.2s;

        &.active, &:hover {
          background-color: $brandColor;
          color: white;
        }

        &:last-child {
          width: 50%;
          border-right: 0;
        }
      }
    }
  }

  &__filters {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    &__item {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
      width: 100%;
    }

    .label {
      color: #32373F;
      font-size: 15px;
      line-height: 18px;
      font-weight: 500;
      margin-bottom: 16px;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: $brandColor;
      border-color: $brandColor;
    }

    .ant-checkbox-checked::after {
      border-color: $brandColor;
    }

    .ant-checkbox-wrapper {
      margin-left: 0 !important;
    }

    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus + .ant-checkbox-inner {
      border-color: $brandColor;
    }
  }

  &__currency_table {
    border-spacing: 0;
    width: 100%;
    margin-top: 30px;

    tr {
      width: 100%;
      height: 50px;

      td {
        border-bottom: 1px solid $borderColor;
        width: 30%;
        color: #858585;
        text-align: right;

        svg {
          margin-right: 10px;
        }
      }

      td:first-child {
        width: 40%;
        text-align: left;
      }

    }

    tr:first-child {
      td {
        color: $darkColor;
        font-size: 15px;
        line-height: 18px;
        font-weight: 500;
      }
    }

    tr:last-child {
      width: 60%;

      td {
        border-bottom: 0;
      }
    }
  }

  &__currency_title {
    color: $darkColor;
    font-size: 15px;
    line-height: 18px;
    font-weight: 500;
    letter-spacing: 0.02em;
    margin: 60px 0 16px;
  }

  &__change_currency_wrapper {
    width: 100%;
    display: flex;

    .custom-input-group {
      margin-bottom: 20px;
      width: 140px;

      input, .ant-select {
        background: white;
      }
    }

    .swap_icon {
      margin: 4px 12px;
      cursor: pointer;
    }
  }

  &__currency_text {
    color: $brandColor;
    margin-top: 14px;
    font-size: 13px;
    line-height: 24px;
  }

  .close_menu {
    display: none;
  }


  @media all and (max-width: 1280px) {
    padding: 30px 10px;
    width: 330px;
    &__change_currency_wrapper {

      .custom-input-group {
        width: 130px;
      }
    }
  }

  @media all and (max-width: 1050px) {
    display: none;


    &.showMenu {
      width: 100%;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      display: block;
      z-index: 100;
      overflow: scroll;

      .close_menu {
        display: block;
        margin-left: auto;
        cursor: pointer;
        margin-bottom: 20px;
        width: 30px;
        height: 30px;
      }
    }
  }

  @media all and (max-width: 800px) {
    padding: 0 $mainPadding;
    .filter_region{
      width: 100%;
      margin-right: unset;
      margin-bottom: 30px;
      .region_block{
        width: 100%;
        .ant-dropdown-trigger{
          .ant-space-align-center{
            width: 92%!important;
            .ant-space-item{
              width: 100%;
            }
          }
        }
      }
    }
    &__form {
      flex-wrap: wrap;
      justify-content: space-between;

      .switch {
        order: 1;
        margin-right: 100%;
        margin-bottom: 30px;
      }

      &__select {
        width: 48%;
        order: 2;
      }

      &__slider {
        width: 100%;
        order: 3;
        margin: 30px 0 0;
      }
    }
  }
}

.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  border-color: $borderColor !important;
}

.filter__form__slider .slider_content .ant-slider .ant-slider-handle:focus {
  box-shadow: none !important;
}

.slick-slider {
  height: 100%;

  .slick-list {
    padding: 0;
  }

  .slider-item {

    img {
      width: 100%;
      height: calc(100vh - 60px);
      object-fit: cover;
      //filter: blur(8px);
    }

    .slider-info-wrapper {
      position: absolute;
      top: 0;
      left: $mainPadding;
      height: 100%;
      width: 100%;
      display: flex;

      .info-content {
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        height: 45%;
        position: relative;
        margin-top: 7%;
        width: 100%;
      }

      .slide-title {
        font-weight: 900;
        font-size: 62px;
        overflow: hidden;
        line-height: 90px;
        width: 50%;
        height: 176px;
      }

      .slide-sub-title {
        font-weight: 400;
        font-size: 20px;
        line-height: 34px;
        width: 50%;
        height: 122px;
        overflow: hidden;
        letter-spacing: 0.4px;
      }

      .link-button {
        width: 218px;
        height: 60px;
      }
    }
  }
}


