@font-face {
  font-family: Gilroy;
  src: url(../fonts/Gilroy-Regular.ttf) format('truetype');
  font-weight: 300 400;
}

@font-face {
  font-family: Mardoto;
  src: url(../fonts/Montserrat-Regular.ttf) format('truetype');
  font-weight: 300 400;
}

@font-face {
  font-family: Mardoto;
  src: url(../fonts/Montserrat-Medium.ttf) format('truetype');
  font-weight: 500 600;
}
@font-face {
  font-family: Gilroy;
  src: url(../fonts/Gilroy-Medium.ttf) format('truetype');
  font-weight: 500 600;
}

@font-face {
  font-family: Mardoto;
  src: url(../fonts/Montserrat-Bold.ttf) format('truetype');
  font-weight: 700 900;
}
@font-face {
  font-family:Gilroy;
  src: url(../fonts/Gilroy-Bold.ttf) format('truetype');
  font-weight: 700 900;
}