@import "../../assets/styles/mainVariables";
@import "../../assets/styles/fonts";
@import "../../assets/styles/mixins";

.search {
  width: $mainContent;
  margin: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  &__header {
    width: 100%;
    height: 55px;
    border-top: 1px solid $borderColor;
    border-bottom: 1px solid $borderColor;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 54px;

    &__selects {
      display: flex;

      .filter_btn {
        height: 37px;
        color: #1F2226;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        display: none;
        cursor: pointer;
      }

      .header_dropdown {
        height: 37px;
        margin-left: 20px;
        padding-left: 20px;
        border-left: 1px solid $borderColor;
        color: #1F2226;
        letter-spacing: 0.5px;

        .arrow_icon {
          transform: rotate(90deg);
          width: 20px;
          height: 20px;
          margin-left: 5px;
        }
      }
    }
  }

  &__posts_list {
    width: calc(100% - 432px);

    .posts_list_title {
      font-size: 24px;
      line-height: 18px;
      letter-spacing: 0.02em;
      color: $darkColor;
      margin-bottom: 40px;
     font-weight: 500;
    }

    .post-items-wrapper{
      display: flex;
      margin-bottom: 70px;
      &.LIST {
        flex-direction: column;
      }
      &.GRID {
        flex-wrap: wrap;
      }
    }


    .card_grid {
      width: calc(25%);
    }

    .empty_list {
      min-height: 40vh;
      width: 100%;
      font-size: 20px;
      line-height: 18px;
      letter-spacing: 0.02em;
      color: #C1C1C1;
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        text-align: center;
        width: 100%;
      }
    }

    &__pagination {
      border: 1px solid $borderColor;
      width: fit-content;

      .ant-pagination-prev, .ant-pagination-next {
        margin: 0;
        padding: 0 10px;
        height: 36px;

        .prev_btn, .next_btn {
          color: #1F2226;
          display: flex;
          align-items: center;
          width: 100%;
          height: 100%;

          .arrow_icon {
            width: 20px;
            height: 20px;
            margin: 0 0 0 10px;
          }
        }
      }

      .ant-pagination-prev {
        border-right: 1px solid $borderColor;

        .prev_btn {
          .arrow_icon {
            transform: rotate(180deg);
            margin: 0 15px 0 0;
          }
        }
      }

      .ant-pagination-item {
        margin: 0;
        border: 0;
        border-radius: 0;
        border-right: 1px solid $borderColor;
        width: 36px;
        height: 36px;

        a {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #1F2226;
          font-weight: 500;
          font-size: 13px;
          margin: 0;
        }

        &:hover {
          background: $brandColor;
          border-right: 1px solid $borderColor;

          a {
            color: white;
          }
        }
      }

      .ant-pagination-item-active {
        background: $brandColor;
        border-color: $borderColor;

        a {
          color: white;
        }
      }

      &.hide-prev{
        .ant-pagination-prev{
          display: none;
        }
      }
    }
  }

  &.map_view {
    width: 100%;

    .search__header {
      padding: 0 calc((100% - #{$mainContent}) / 2);
      margin-bottom: 0;
    }

    .search__posts_list {
      height: calc(100vh - 236px);
      width: calc(100% - 390px);
    }

    .filter {
      height: calc(100vh - 236px);
      min-height: unset;
      overflow-y: auto;
      @include custom-scrollbar-y;
    }
  }


  @media (max-width: 1680px) {
    &__posts_list {
      .card_grid {
        width: calc(100% / 3);
      }
    }
  }

  @media all and (max-width: 1280px) {
    &__posts_list {
      width: calc(100% - 350px);
    }
    &.map_view {
      .search__posts_list {
        width: calc(100% - 330px);
      }
    }
  }

  @media all and (max-width: 1050px) {

    &__header {
      .ant-breadcrumb {
        display: none;
      }

      &__selects {
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        .filter_btn {
          display: flex;
          align-items: center;
          font-size: 12px;
        }
        .header_dropdown{
          font-size: 12px;
          margin-left: 10px;
          padding-left: 10px;
        }
      }
    }
    &__posts_list {
      width: 100%;

      .posts_list_title{
        padding: 0 20px;
      }

      .post-items-wrapper{
        padding: 0 20px;
      }

      .card_grid {
        width: calc(100% / 3);
      }
    }
    &.map_view {
      padding: 0;

      .search__header {
        padding: 0 $mainPadding;
      }

      .search__posts_list {
        width: 100%;
      }
      .filter {
        height: 100vh;
      }
    }
  }

  @media all and (max-width: 650px) {

    &__posts_list {
      .card_grid {
        width: calc(50%);
      }
    }
  }

  @media all and (max-width: 400px) {

    &__posts_list {
      .card_grid {
        width: 100%;
      }
    }
  }
}
