@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/fonts";

.post_slider {
  margin-bottom: 60px;

  .slick-slider {
    width: 100%;
    height: 100%;

    .slider-item {
      position: relative;
      padding-top: 100%;
      z-index: 999;

      img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
      }
    }

    .slick-dots {
      bottom: 0;

      li {
        button:before {
          content: ' ';
          width: 24px;
          height: 3px;
          background-color: white;
        }

        &.slick-active {
          button:before {
           background-color: $darkColor;
          }
        }
      }
    }
  }

  .slick-slider {
    height: 100%;

    .slick-list {
      padding: 0;
    }

    &:hover{
      .slider-btn{
        opacity: unset!important;
      }
    }

    .slider-item {
      position: relative;

      img {
        width: 100%;
        object-fit: cover;
        z-index: -4;
      }

      .slider-btn {
        display:flex;
        align-items: center;
        justify-content: center;
        transition: all .2s;
        width: 32px;
        height: 64px;
        background-color: $darkColor;
        cursor: pointer;
        position: absolute;
        opacity: 0;
        top: 50%;
        z-index: 1000;
transform: translateY(-50%);

        img, svg {
          width: 12px;
          height: 12px;
        }

        &.prev-btn {
          left: 16px;
        }
        &.next-btn {
          right: 16px;
        }
      }


      .buttons {
        position: absolute;
        left: 16px;
        bottom: 16px;
        display: flex;
        cursor: pointer;
        z-index: 1000;

        button {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }

        &__all {
          width: 108px;
          height: 38px;
          background-color: $darkColor;
          color: white;
          margin-right: 6px;
        }

        &__view_360 {
          width: 108px;
          height: 38px;
          background-color: white;
          color: $darkColor;
        }
      }
    }
  }
}