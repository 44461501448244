@import "../../assets/styles/mainVariables";
@import "../../assets/styles/mixins";

.auth_wrapper {
  width: 360px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 6% auto 80px;

  &__logo {
    margin-bottom: 80px;
  }

  .title {
    font-size: 24px;
    line-height: 18px;
    font-weight: 500;
    letter-spacing: 0.02em;
    color: #1F2226;
    margin-bottom: 50px;
    align-self: flex-start;
  }

  .text {
    font-weight: 400;
    font-size: 15px;
    line-height: 135.9%;
    letter-spacing: 0.02em;
    color: #1F2226;
    margin-bottom: 40px;
    align-self: flex-start;
    .email {
      color: $brandColor;
    }
  }

  .error-message {
    color: $redColor;
    display: flex;
    align-items: center;
    font-size: 12px;

    &.show {
      visibility: visible;
      opacity: 1;
      height: 20px;
      padding-top: 10px;
    }

    &.hide {
      visibility: hidden;
      opacity: 0;
      height: 0;
      padding-top: 0;
    }

    @media screen and (max-width: 700px) {
      font-size: 11px;
    }
  }

  .custom-input-group {
    width: 100%;
    margin-bottom: 30px;

    input {
      border-radius: 2px;
      margin-bottom: 0;
      height: 42px;
      padding: 8px 12px;

      &::placeholder {
        color: #b1b1b1;
        font-size: 12px;
      }

      &.phone {
        padding: 8px 12px 8px 50px;
      }
    }

    .phone-code {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 12px;
      color: #bfbfbf;
      font-size: 12px;
      border-right: 1px solid #bfbfbf;
      padding-right: 6px;
    }
  }

  .button{
    width: 100%;
    margin-top: 14px;
  }
  //.sign-up {
  //  .custom-input-group {
  //
  //    &:nth-child(6) {
  //      margin-bottom: 30px;
  //    }
  //  }
  //
  //  .accept-privacy-policy {
  //    display: flex;
  //    align-items: flex-start;
  //
  //    .privacy-policy-checkbox {
  //      &.error .ant-checkbox-inner {
  //        border-color: red !important;
  //      }
  //    }
  //
  //    .privacy-checkbox-title {
  //      margin-left: 10px;
  //    }
  //  }
  //
  //  .privacy-policy-text {
  //    margin-left: 10px;
  //    font-size: 12px;
  //
  //    .privacy-policy-link {
  //      color: black;
  //
  //      a {
  //        color: $brandColor;
  //      }
  //    }
  //  }
  //}

  @media all and (max-width: 500px) {
    align-items: center;
    width: 100%;
    padding: 20px $mainPadding;
  }
}
