@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/fonts";


.code-container {
  display: flex;
  align-items: center;
  margin-top: 8px;

  .custom-input-group {
    margin: 0 4px;
  }

  .code {
    border-radius: 2px;
    font-size: 24px;
    height: 44px;
    width: 44px;
    margin-bottom: 0;
    border: 1px solid #eee;
    text-align: center;
    font-weight: 300;
    -moz-appearance: textfield;
    display: flex;
    align-items: center;
    justify-content: center;

    &:valid {
      border-color: $brandColor;
      box-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.1);
    }

    &:focus {
      outline: none;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
    }
  }

  .code::-webkit-outer-spin-button,
  .code::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
