@import "../../../../assets/styles/mainVariables";
@import "../../../../assets/styles/mixins";

.notification-details {

  padding: 15px 20px 15px 40px;

  .notification-slider-wrapper {
    width: 30%;
    margin: 0 40px 20px 20px;
    float: left;

    .image-wrapper {
      width: 100%;
      position: relative;

      .main-slider-items {
        position: relative;
        width: 100%;
        padding-top: 100%;

        .images-slider {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;

          .arrows {
            transition: .2s;

            .prev-btn, .next-btn {
              //width: 34px;
              //height: 34px;
            }
          }
        }
      }

      .slick-slider {
        height: 100%;

        .slick-dots {
          display: flex;
          justify-content: center;
          list-style-type: none;

          li {
            margin: 0 6px;
            width: 30px;
            height: 2px;
          }

          button {
            display: block;
            width: 100%;
            height: 100%;
            padding: 0;
            border: none;
            border-radius: 10px;
            background-color: #BEBEC3;

            text-indent: -9999px;
          }

          li.slick-active button {
            background-color: $brandColor;
          }
        }

        .slider-item {
          position: relative;
          width: 100%;
          padding-top: 100%;

          &.slide-link {
            cursor: pointer;
          }

          img {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 6px;
          }

          .slider-info-wrapper {
            position: absolute;
            top: 0;
            left: 7.5%;
            color: black;
            height: 100%;
            width: 92.5%;
            display: flex;
            align-items: center;

            .info-content {
              display: flex;
              justify-content: space-between;
              flex-direction: column;
              height: 66%;
              width: 100%;
              position: relative;

              .PrimaryButton {
                text-transform: uppercase;
              }
            }

            .slide-title {
              @include font(Bold, 50);
              overflow: hidden;
              line-height: 62px;
              width: 55%;
              min-width: 300px;
              height: 130px;

              span {
                color: $brandSecColor;
              }
            }

            .slide-sub-title {
              font-size: 16px;
              width: 41%;
              height: 50px;
              overflow: hidden;
              letter-spacing: 0.23px;
            }

            .PrimaryButton {
              width: 160px;
              height: 46px;
              border-radius: 4px;
              background-color: $brandColor;
            }
          }
        }
      }
    }
  }

  .notification-title {
    @include font(Medium, 28);
    line-height: 36px;
    margin-bottom: 16px;
  }

  .date {
    @include font(Regular, 14);
    color: #8a8a8a;
    margin-bottom: 14px;
  }

  .notification-description {
    color: black;
    font-size: 15px;
  }

  @media all and (max-width: 1260px) {
    .notification-slider-wrapper {
      width: 40%;
    }
  }
  @media all and (max-width: 1150px) {
    padding: 15px 20px;
  }

  @media all and (max-width: 1000px) {
    .notification-slider-wrapper {

      .image-wrapper {

        .main-slider-items {

          .images-slider {

            .arrows {
              display: flex;
            }
          }
        }
      }
    }
  }

  @media all and (max-width: 700px) {
    padding: 10px;
    .notification-slider-wrapper {
      width: 100%;
      margin: 0 0 40px 0;
    }
  }
}