@import "../../../assets/styles/mainVariables";

.main_header {
  width: 100%;
  padding: 18px calc((100% - #{$mainContent}) / 2);
  display: flex;
  justify-content: space-between;
  align-items: center;

  .mobile-signIn{
    background-color: transparent;
    padding: unset!important;
  }

  .header_dropdown{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__text{
      font-size: 15px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.02em;
    }
    svg{
      margin-left: 12px;
    }
  }

  .user_dropdown{
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__fullName{
      font-size: 15px;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: 0.02em;
      margin-left: 12px;
    }
  }

  &__left_part {
    display: flex;
    align-items: center;
    //a{
    //  svg{
    //    width: 168px;
    //    height: 91px;
    //  }
    //}
  }

  .sine-in{
    display: flex;
    align-items: center;
    margin-right: 10px;
    svg{
      margin-left: 10px;
    }
    a{
      display: contents;
      p{
        color: white;
        background-color: #E34256;
        width: 18px;
        height: 18px;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-weight: 500;
        line-height: 9px;
      }
    }
  }

  .menu-item {
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
    color: #E34256;
    margin-right: 20px;
    border: 2px solid #E34256;
    height: 32px;
    padding: 0 42px;
  }

  &__menu {
    display: flex;
    align-items: center;
  }

  &__menu_icon {
    display: none;
  }

  &__logo_mobile{
    display: none;
  }
  .mobile_block{
    display: none;
  }

  @media screen and (max-width: 1300px){
    &__menu {
      .menu-item {
        margin-right: 16px;
      }

    }
  }

  @media screen and (max-width: 1050px) {
    padding: 18px $mainPadding;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    &__logo {
      cursor: pointer;
      height: 60px;
    }

    &__menu {
      display: none;
    }

    &__menu_icon {
      display: block;
      cursor: pointer;
    }

    &__logo{
      display: none;
    }
    &__logo_mobile{
      display: block;
    }
    .mobile_block{
      display: flex;
      align-items: center;
    }
  }
  @media screen and (max-width: 1050px) {
    &__menu_icon {
      order: 2;
    }

    &__logo_mobile{
      display: block;
      order: 1;
      width: 100px;
    }

    &__left_part{
      width: 100%;
      order: 3;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media screen and (max-width: 400px){

    &__logo_mobile{
      width: 80px;
    }

    .mobile_block{
      width: 64%;
      justify-content: space-between;
      .menu-item{
        margin-right: unset;
        height: 32px;
        padding:0  30px;
      }
    }
  }
}