@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/mixins";

.compare_wrapper{
  width: 100%;
  margin-left: 60px;
  .sc-kDvujY{
    height: unset;
  }
  .back{
    display: none;
  }
  .compare-empty-view{
    width: 100%;
    margin-top: 304px;
    display: flex;
    align-self: center;
    justify-content: center;
    .empty-compare{
      align-self: center;
      justify-content: center;
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      text-align: center;
      color: #C1C1C1;
    }
  }
  .compare_title{
    font-size: 24px;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0;
    text-align: left;
    margin-bottom: 28px;
  }
  .compare-products-wrapper{
    width: 100%;
    display: flex;
    .compare-products-description{
      border: 1px solid #F4F0EB;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      margin-bottom: 6px;
      .products-text{
        height: 267px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        .inform_block{
          width: 100%;
          height: calc(100% / 4);
          border-bottom: 1px solid #F4F0EB;
          font-size: 15px;
          font-weight: 500;
          line-height: 18px;
          color: $brandColor;
          white-space: nowrap;
          padding: 19px 16px 0 16px;
          max-width: 128px;
          text-overflow: ellipsis;
          overflow: hidden;
          &:nth-child(even){
            background-color: #FBFAF9;
          }
          &:first-child{
            border-top: 1px solid #F4F0EB;
          }
          &:last-child{
            border-bottom: unset;
          }
        }
      }
    }
    .compare-products{
      display: flex;
      overflow-x: scroll;
      border-right: 1px solid #F4F0EB;
    }
    .wrappers-width{
      width: 80%;
    }
    ::-webkit-scrollbar {
      background: transparent;
      height: 6px;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
    }

    ::-webkit-scrollbar-thumb {
      -webkit-border-radius: 10px;
      border-radius: 10px;
      position: relative;
      background: #EAE1D8;
    }
  }
  @media screen and (max-width: 800px){
    margin-left: unset;
    .mobile-back{
      display: flex;
      align-items: center;
      margin-bottom: 26px;
      span{
        font-size: 13px;
        font-weight: 500;
        line-height: 16px;
        color: #C1C1C1;
        margin-left: 12px;
        margin-top: 2px;
      }
    }
  }
}
