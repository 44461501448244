@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/fonts";


.header{
  width: 100%;
  height: 260px;
  background-color:#CDBB99;
  margin: 80px 0;

  &__block{
    width: #{$mainContent};
    margin: 0 auto;
    position: relative;

    svg{
      position: absolute;
      right: 0;
      top: 26px;
      bottom: 26px;
    }

    &__title{
      font-weight: 900;
      font-size: 48px;
      line-height: 59px;
      color: white;
      position: absolute;
      right: 240px;
      top: 160px;
      width: 38%;
    }
  }
  @media screen and (max-width: 1050px){
      &__block{
        width: 98%;
        padding: 0 20px;

        &__title{
          width: 36%;
        }
      }
    }
  @media screen and (max-width: 800px){

      &__block{
        svg{
          width: 80%;
        }
        &__title{
          width: 90%;
          right: unset;
          top: 136px;
        }
      }
    }

  @media screen and (max-width: 500px){

    &__block{
      &__title{
        font-size: 28px;
      }
    }
  }
}