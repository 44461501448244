@import "../../assets/styles/mainVariables";
@import "../../assets/styles/fonts";
@import "../../assets/styles/mixins";


.post_section {
  width: 100%;
  padding: 70px calc((100% - #{$mainContent}) / 2) 0;
  position: relative;

  &__slider {
    width: 100%;
    position: relative;

    &__arrow {
      width: 32px;
      height: 64px;
      background-color: #7C7C7C1F;
      position: absolute;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.2s;
      cursor: pointer;
      top: 50%;
      &.next, &.prev {
        svg {
          path{
            stroke: #858585;
            transition: all 0.2s;
          }
        }
      }
      &.prev {
        left: calc((100% - #{$mainContent}) / 2 - 60px);

        svg {
          transform: rotate(180deg);
        }
      }

      &.next {
        right: calc((100% - #{$mainContent}) / 2 - 60px);
      }

      &:hover {
        background-color: $brandColor;
        &.next, &.prev {
          svg {
            path{
              stroke: white;
            }
          }
        }
      }
    }
  }

  &__list {
    position: relative;
    overflow: hidden;
    width: calc(100% + 20px);

    .slick-track {
      margin-left: unset;
      padding: 30px 0;
      //height: 550px;
    }

    .product-card-wrapper {
      width: 100%;
      padding: 0 10px;
    }
    .empty{
      width: 100%;
      height: 260px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
      color: #C1C1C1;
    }
  }

  .ant-tabs {
    .ant-tabs-nav:before {
      border-bottom: 1px solid $borderColor;
    }

    .ant-tabs-nav-wrap {
      .ant-tabs-nav-list {
        .ant-tabs-tab {
          font-size: 14px;
          line-height: 18px;
          text-align: center;
          letter-spacing: 0.02em;
          padding: 10px 10px;

          &.ant-tabs-tab-active {
            border-color: black;

            &:before {
              border-color: black;
            }

            .ant-tabs-tab-btn {
              color: black;
            }
          }

          &:hover {
            color: black;
          }
        }

        .ant-tabs-ink-bar {
          background: black;
          height: 1px;
        }
      }
    }
  }

  .all_btn{
    display: none;
  }

  &.search_section{
    width: 100%;
    padding: 70px 30px;
    position: relative;
    .post_section__slider__arrow{
      &.prev{
        left: -50px;
      }
      &.next{
        right: -50px;
      }
    }
  }

  @media all and (max-width: 1350px) {
    &__slider {

      &__arrow {

        &.prev {
          left: calc((100% - #{$mainContent}) / 2 - 50px);
        }

        &.next {
          right: calc((100% - #{$mainContent}) / 2 - 50px);
        }

        &:hover {
          background-color: $brandColor;
        }
      }
    }

    &.search_section{
      padding: 70px 40px;
      .post_section__slider__arrow{
        &.prev{
          left: -50px;
        }
        &.next{
          right: -50px;
        }
      }
    }
  }

  @media all and (max-width: 1200px) {
    &__slider {

      &__arrow {

        &.prev {
          left: calc((100% - #{$mainContent}) / 2 - 40px);
        }

        &.next {
          right: calc((100% - #{$mainContent}) / 2 - 40px);
        }

        &:hover {
          background-color: $brandColor;
        }
      }
    }
  }

  @media all and (max-width: 1100px) {
    &__slider {

      &__arrow {
        display: none;
      }
    }

    &__list{
      width: 100%;
    }
  }

  @media all and (max-width: 1050px) {
    margin-bottom: 0;
    padding: 30px 0;
    .all_btn{
      display: block;
    }
    &__header{
      width: 100%;
      flex-direction: column;
    }
  }
}
