@import "../../../assets/styles/mainVariables";

.info_header {
  width: 100%;
  padding: 0 calc((100% - #{$mainContent}) / 2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #A18E771A;
  height: 48px;


  &__privacy {
    &__link {
      color: #517ad6;
      margin-left: 6px;
    }
  }

  &__actions {
    display: flex;
    align-items: center;

    &__text{
      width: 60%;
      text-transform: uppercase;
      color: $brandColor;
      font-weight: 600;
      font-size: 12px;

      &:hover{
        color: $brandColor;
      }
    }

    &__dropdowns{
      display: flex;
      align-items: center;
    }

    .header_img {
      margin-right: 48px;
    }

    .language_dropdown_item {
      margin-left: 26px;
      font-size: 13px;
      line-height: 16px;
      color: black;


      img {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        margin-right: 6px;
      }

      .arrow_icon{
        transform: rotate(90deg);
        width: 20px;
        height: 20px;
        margin-left: 5px;
      }
    }
  }

  @media screen and (max-width: 1050px) {
    flex-wrap: wrap;
    padding: 8px $mainPadding;
    height: 70px;
    &__privacy{
      width: 100%;
    }

    &__actions {
      width: 100%;
      justify-content: space-between;


      .header_img {
        display: none;
      }

      .language_dropdown_item {
        margin-left: 26px;
        font-size: 13px;
        line-height: 16px;
        color: black;

        img {
          width: 16px;
          height: 16px;
          border-radius: 50%;
          margin-right: 6px;
        }

        .arrow_icon{
          transform: rotate(90deg);
          width: 20px;
          height: 20px;
          margin-left: 5px;
        }
      }
    }
  }

  @media screen and (max-width: 700px) {
    height: 108px;
  }
  @media screen and (max-width: 400px) {
    &__actions{
      &__text{
        font-size: 10px;
      }
    }
  }
}

.ant-dropdown {
  .ant-dropdown-menu {
    .ant-dropdown-menu-item {
      .lang_menu_item {
        display: flex;
        color: black;

        img {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          margin-right: 6px;
        }

        svg{
          width: 20px;
          height: 20px;
          margin-right: 6px;
        }
      }
    }
  }
}
