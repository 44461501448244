@import "../.././assets/styles/mainVariables";
@import "../.././assets/styles/fonts";

.about_wrapper{
  width: 100%;
  margin: auto;

  &__components{
    width: #{$mainContent};
    margin: 0 auto;

    &__firstBlock{
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;

      &__description{
        width: 46%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .title{
          font-weight: 400;
          font-size: 24px;
          line-height: 29px;
          color:$darkColor;
        }
        .text{
          margin-top: 20px;
          font-size: 15px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0.02em;
          color: #858585;
        }
        .buttons{
          display: flex;
          justify-content: space-between;
          width: 80%;
          align-items: center;
          margin-top: 36px;

          &__apartment{
            width: 46%;

            &:hover{
              background-color: #B79B7A;
            }
          }

          &__contact{
            width: 46%;
            color:$brandColor;
            border-color:$brandColor;
            &:hover{
              border-color: #B79B7A;
              color: #B79B7A;
            }
          }
        }
      }

      &__img{
        width: 46%;
        &__wrapper{
          position: relative;
          padding-top: 66%;
          img{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }

    &__secondBlock{
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 100px;

      .secondBlock_wrapper{
        width:calc(100% / 3);
        display: flex;
        align-items: center;

        .info_block{
          width: 70%;
          .title{
            font-weight: 400;
            font-size: 20px;
            line-height: 24px;
            color:$brandColor;
            width: 80%;
            margin-bottom: 16px;
          }
          .description{
            font-size: 13px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0.015em;
            color:$darkColor;
          }
        }

        .line{
          height: 200px;
          width: 1px;
          background: #E0E0E0;
        }

        &:first-child{
          justify-content: flex-start;
          .line{
            margin-left: 80px;
          }
        }

        &:nth-child(2){
          justify-content: center;
          margin-bottom: 20px;
          .line{
            margin-left: 60px;
          }
        }
        &:nth-child(3){
          width: 30%;
          justify-content: flex-end;
          .info_block{
            width: 90%;
          }
          .line{
            display: none;
          }
        }
      }

    }
  }

  @media screen and (max-width: 1050px){
    &__components{
      padding: 8px $mainPadding;
    }

  }

  @media screen and (max-width: 800px){
    &__components{
      &__firstBlock{
        flex-direction: column-reverse;
        &__description{
          width: 100%;
          margin-top: 30px;
          align-items: center;
          justify-content: center;
          text-align: center;
        }
        &__img{
          width: 100%;
        }
      }

      &__secondBlock{
        flex-direction: column;
        .secondBlock_wrapper{
          width: 100%;
          flex-direction: column;
          .info_block{
            width: 70%;
            text-align: center;
            .title{
              width: 100%;
            }
          }
          .line{
            width: 100%;
            height: 1px;
            margin-top: 40px;
            margin-left: unset!important;
          }
          &:nth-child(3){
            width: 70%;
            margin-top: 40px;
          }
          &:nth-child(2){
            margin-top: 40px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 500px){
    &__components{
      &__firstBlock{
        &__description{
          .buttons{
            width: 100%;
          }
        }
      }
    }
  }
}
