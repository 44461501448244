@import "../../assets/styles/mainVariables";
@import "../../assets/styles/mixins";

.user-details-wrapper {
  width: #{$mainContent};
  margin: 0 auto;
  .user-details-content {
    display: flex;
    position: relative;
    width: 100%;
    padding: 80px 0 60px 0;
    .left-side-menu {
      width: 25%;
      background-color: #F8F5F2;
      display: flex;
      flex-direction: column;

      .left-side-menu-header {
        margin: 15px 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-bottom: 1px solid rgba(205, 187, 153, 0.4);

        .menu-header-content {
          color: black;

          .header-name {
            font-size: 20px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0;
            text-align: center;
            width: 100%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            margin-top: 14px;
          }

          .header-email {
            width: 100%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            font-size: 13px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0.015em;
            text-align: left;
            color: #858585;
            margin-bottom: 50px;

          }
        }
      }

      .aside-menu-list {
        list-style: none;
        padding: 20px 0;
        margin-top: 40px;

        li {
          font-size: 13px;
          font-weight: 500;
          line-height: 16px;
          letter-spacing: 0;
          text-align: left;


          svg {
            margin-right: 10px;

            path {
              transition: all .2s linear;
            }
          }

          a {
            display: flex;
            align-items: center;
            height: 100%;
            padding: 15px 30px;
            color: black;

            span {
              font-size: 13px;
              font-weight: 400;
              line-height: 18px;
              letter-spacing: 0.015em;
              text-align: left;

              &:hover {
                color: $brandColor;
              }
            }
          }

          .active {
            font-size: 13px;
            font-weight: 500;
            line-height: 16px;
            letter-spacing: 0;
            text-align: left;
            color: $brandColor;
          }

          &:hover {
            color: $brandColor;

            svg {
              path {
                fill: $brandColor;
              }
            }
          }

          &.logout-wrapper {
            padding: 15px 30px;
            margin-top: 40px;
            display: flex;
            align-items: center;

            span {
              cursor: pointer;
            }
          }
        }

        .menu-notification {
          .badge {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 12px;
            background-color: $brandSecColor;
            width: 16px;
            height: 16px;
            color: white;
            border-radius: 50%;
            @include flex-center;
            font-size: 9px;
            font-weight: 500;
          }
        }
      }
    }

    .profile-content-wrapper {
      width: 75%;

      .profile-content-header {
        position: sticky;
        top: 106px;
        z-index: 2;
        background-color: white;
        padding: 15px 40px;
        border: 1px solid $borderColor;
        color: black;
        font-weight: 500;
        font-size: 20px;
        height: 80px;
        display: flex;
        align-items: center;

        .notification-details-header {
          display: flex;
          align-items: center;

          svg {
            margin-right: 12px;

            polygon {
              transition: .2s;
              fill: black;
            }

            &:hover {
              polygon {
                fill: $brandColor;
              }
            }
          }
        }
      }

      .content-wrapper {
        border-top: 0;
        min-height: 644px;
      }
    }

    @media screen and (max-width: 1000px) {
      padding: 20px;
    }
    @media screen and (max-width: 800px) {
      .mobile-menu {
        width: 100%;
      }
      .menu-none {
        display: none;
      }
      .profile-content-wrapper {
        width: 100%;
      }
      .mobile-profile {
        display: none;
      }
    }
  }
}
