@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/mixins";

.notifications-wrapper {
  margin-left: 50px;
  .back{
    display: none;
  }
  .notifications-title{
    font-size: 24px;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0;
    text-align: left;
    margin-bottom: 24px;
  }
  .notifications-content-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 4px 0 rgba(47, 51, 56, 0.16), inset 0 0 0 15px #fff;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 15px 40px;
    height: 80px;


    .notifications-title {
      color: black;
      font-weight: 600;
      font-size: 15px;
    }

    .notifications-switch-wrapper {
      color: black;

      .ant-switch {
        margin-left: 20px;
        min-width: 38px;
      }

      .ant-switch-checked {
        background-color: $brandColor;
      }
    }
  }

  .notifications-empty-view {
    width: 100%;
    margin-top: 304px;
    display: flex;
    align-self: center;
    justify-content: center;
    p {
      align-self: center;
      justify-content: center;
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      text-align: center;
      color: #C1C1C1;
    }
  }



  .notifications {
    min-height: 644px;

    .notification-item-wrapper{
      position: relative;
      .notification-item-card {
        width: 100%;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid $borderColor;
        padding:24px 16px 44px 24px;
        transition: .2s;
        position: relative;

        &:hover{
          background-color: #F8F5F2;
          .title{
            color: #B79B7A!important;
          }
          .delete-content{
            visibility: unset!important;
            opacity: unset!important;
          }
        }

        &.link-notification {
          cursor: pointer;
        }
        .delete-content {
          visibility: hidden;
          opacity: 0;
          cursor: pointer;
          transition: .2s;

          svg{
            path{
              fill:#B79B7A ;
            }
          }
        }

        .left-part {
          display: flex;
          align-items: center;
          width: 90%;
          height: 100%;


          .image-wrapper {
            height: 50px;
            width: 50px;
            background-color: $brandColor;
            margin-right: 20px;
            border-radius: 10px;
            @include flex-center;

            svg {
              width: 29px;
              height: 26px;

              path {
                fill: white;
              }
            }
          }

          .notification-content {
            width: 100%;

            .title {
              font-size: 18px;
              font-weight: 400;
              line-height: 27px;
              color: #1F2226;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .description-wrapper {

              .description {
                margin-top: 12px;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 15px;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0.02em;
                color: #858585;

                a{
                  color:#B79B7A!important;
                }



                &.show-all {
                  height: unset;
                  overflow: unset;
                  white-space: unset;
                }

              }

              .see-more {
                color: $brandColor;
                @include font(Regular, 13);
              }
            }
          }
        }

        .right-part {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-end;

        }

        .notification-date {
          color: $greyTextColor;
          font-size: 13px;
        }

        &:hover {
          .right-part {
            .delete-content {
              visibility: visible;
              opacity: 1;
            }
          }
        }
      }
      .right-part{
        position: absolute;
        bottom: 16px;
        right: 16px;
        .notification-date{
          font-size: 15px;
          font-weight: 500;
          line-height: 18px;
          color: #E0E0E0;
        }
      }
    }
  }

  @media screen and (max-width: 800px){
    margin-left: unset;
    .mobile-back{
      display: flex;
      align-items: center;
      margin-bottom: 26px;
      span{
        font-size: 13px;
        font-weight: 500;
        line-height: 16px;
        color: #C1C1C1;
        margin-left: 12px;
        margin-top: 2px;
      }
    }
  }
}
