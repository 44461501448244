@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/fonts";

.banners {
  width: #{$mainContent};
  margin: 0 auto 90px;

  &__title {
    font-size: 24px;
    line-height: 18px;
    margin-bottom: 56px;
    margin-top: 64px;
   font-weight: 600;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;

    .offer_item{
      width: 48%;
      height: 190px;
      position: relative;
      border-radius: 4px;
      overflow: hidden;

      &__bg_img{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
        transition: 1s;
        display: block;

        &:hover{
          transform: scale(1.2);
        }
      }

      &__content{
        position: absolute;
        bottom: 0;
        left: 0;
        color: white;
        width: 100%;
        padding-left: 18px;
        padding-bottom: 18px;
        background:linear-gradient(rgba(0, 0, 0, 0) , rgba(0, 0, 0, 0.53));
      }
      &__title{
        font-size: 18px;
        line-height: 22px;
        font-weight: 900;
        margin-bottom: 8px;
      }
      &__description{
        font-size: 13px;
        line-height: 20px;
      }
    }

    .offer_item_more{
      width: 48%;
      height: 190px;
      border-radius: 4px;
      overflow: hidden;
      display: flex;


      img{
        width: auto;
        height: 100%;
        transition: 1s;
        display: block;

        &:hover{
          transform: scale(1.2);
        }
      }

      &__content{
        width: 62%;
        padding: 24px 0 18px 30px;
        color: black;
        border: 1px solid #E0E0E0;
        border-left: unset;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        //display: flex;
        //flex-direction: column;
        //justify-content: center;
      }
      &__title{
        font-size: 17px;
        line-height: 21px;
        font-weight: 700;
        margin-bottom: 8px;
      }
      &__description{
        font-size: 13px;
        line-height: 20px;
        margin-bottom: 29px;
      }

      &__btn{
        background-color: #CDBB99;
        height: 44px;
        svg {
          transition: transform 0.2s;
          transform: rotate(0deg);
        }

        &:hover {
          svg {
            transform: rotate(0deg) translateX(5px);
          }
        }
      }
    }
  }

  @media screen and (max-width: 1300px){
    &__wrapper {
      .offer_item_more{
        &__btn{
          padding: 10px 12px;
        }
      }
    }
  }

  @media screen and (max-width: 1050px) {
    padding: 0 $mainPadding;

    &__title {
      margin: 20px 0 40px ;
    }

    &__wrapper {

      .offer_item{
        width: 49%;
        height: 160px;

        &__content{
          width: 80%;
          padding-left: 18px;
          padding-bottom: 18px;
        }
        &__title{
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 8px;
        }
        &__description{
          font-size: 13px;
          line-height: 20px;
        }
      }

      .offer_item_more{
        width: 49%;
        height: 160px;
        img{
          width: 120px;
          object-fit: cover;
        }
        &__content{
          width: calc(100% - 120px);
          padding: 16px;
          color: black;
        }
        &__title{
          font-size: 15px;
          line-height: 18px;
          margin-bottom: 8px;
        }
        &__description{
          font-size: 12px;
          line-height: 18px;
          margin-bottom: 22px;
        }
        &__btn{
          width: 100%;
        }
      }
    }
  }

  @media screen and (max-width: 800px) {
    &__wrapper {
      flex-wrap: wrap;

      .offer_item{
        width: 100%;
        height: 190px;
        margin-bottom: 20px;
      }

      .offer_item_more{
        width: 100%;
        height: 190px;


        img{
          width: auto;
          height: 100%;
        }

        &__description{
          font-size: 13px;
          line-height: 20px;
          margin-bottom: 29px;
        }
      }
    }
  }

  @media screen and (max-width: 500px) {
    &__wrapper {
      flex-wrap: wrap;

      .offer_item{
        width: 100%;
        height: 140px;

        &__content{
          width: 80%;
          padding-left: 18px;
          padding-bottom: 18px;
        }
        &__title{
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 8px;
        }
        &__description{
          font-size: 13px;
          line-height: 20px;
        }
      }

      .offer_item_more{
        width: 100%;
        height: 180px;
        img{
          width: 100px;
          object-fit: cover;
        }
        &__content{
          width: calc(100% - 100px);
          padding: 16px;
          color: black;
        }
        &__title{
          font-size: 15px;
          line-height: 18px;
          margin-bottom: 8px;
        }
        &__description{
          font-size: 11px;
          line-height: 14px;
          margin-bottom: 12px;
          height: 28px;
          overflow: hidden;
        }

        &__btn{
          padding: 10px 4px;
          font-size: 11px;
        }
      }
    }
  }
}