@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/mixins";

.favorites-wrapper{
  width: 100%;
  margin-left: 60px;
  .back{
    display: none;
  }
  .favorites-title{
    font-size: 24px;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0;
    text-align: left;
    margin-bottom: 24px;
  }
  .favorite-blocked-products{
    width: 100%;
    display: flex;
    flex-wrap: wrap!important;

    .favorite-empty-view{
      width: 100%;
      margin-top: 304px;
      display: flex;
      align-self: center;
      justify-content: center;
      p {
        align-self: center;
        justify-content: center;
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
        text-align: center;
        color: #C1C1C1;
      }
    }

    .favorite-card{
      width: calc(100% / 3);
    }
  }
  .pagination{
    margin-top: 55px;
  }
  @media screen and (max-width: 800px){
    margin-left: unset;
    .mobile-back{
      display: flex;
      align-items: center;
      margin-bottom: 26px;
      span{
        font-size: 13px;
        font-weight: 500;
        line-height: 16px;
        color: #C1C1C1;
        margin-left: 12px;
        margin-top: 2px;
      }
    }
    .favorite-blocked-products{
      .favorite-card{
        width: calc(100% / 2);
      }
    }
  }
}
