@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/fonts";

.home_currency {
  width: calc((100% - #{$mainContent}) / 2 + #{$mainContent});
  margin-left: auto;
  background-color: #CDBB991F;
  margin-top: 70px;
  display: flex;

  &__table_wrapper {
    background-color: $brandColor;
    width: 365px;
    padding: 42px 42px 22px;
    color: white;

    &__title {
      font-size: 24px;
      line-height: 18px;
      letter-spacing: 0.02em;
      font-weight: 500;
      margin-bottom: 67px;
    }

    table {
      border-spacing: 0;
      width: 100%;

      tr {
        width: 100%;
        height: 50px;

        td {
          border-bottom: 1px solid white;
          width: 30%;
          text-align: right;

          svg{
            margin-right: 10px;
          }
        }

        td:first-child {
          width: 40%;
          text-align: left;
        }

      }

      tr:last-child {
        width: 60%;

        td {
          border-bottom: 0;
        }
      }
    }
  }

  &__change_currency_wrapper {
    width: calc(100% - 365px);
    padding: 42px 76px;

    &__title {
      margin-bottom: 42px;
      font-size: 24px;
      line-height: 18px;
      letter-spacing: 0.02em;
      color: black;
      font-weight: 500;
    }

    &__form {
      display: flex;

      .custom-input-group {
        margin-bottom: 20px;
        width: 196px;
        input, .ant-select {
          background: white;
        }
      }

      .swap_icon{
        margin: 4px 12px;
        cursor: pointer;
      }
    }

    &__text{
      color: $brandColor;
      margin-top: 14px;
      font-size: 13px;
      line-height: 24px;
    }
  }

  @media screen and (max-width: 1050px) {
    &__table_wrapper {
      width: 300px;
      padding: 30px $mainPadding 22px;
      color: white;

      &__title {
        font-size: 20px;
        margin-bottom: 30px;
      }
    }

    &__change_currency_wrapper {
      width: calc(100% - 300px);
      padding: 42px $mainPadding;

      &__title {
        margin-bottom: 32px;
        font-size: 21px;
      }

      &__form {
        .custom-input-group {
          margin-bottom: 20px;
          width: 170px;
        }
      }
    }
  }

  @media screen and (max-width: 750px) {
    flex-wrap: wrap;
    &__table_wrapper {
      width: 100%;

      &__title {
        font-size: 20px;
        margin-bottom: 30px;
      }
    }

    &__change_currency_wrapper {
      width: 100%;

      &__title {
        margin-bottom: 32px;
        font-size: 21px;
      }

      &__form {
        .from, .to {
          width: 40%;
          max-width: 170px;

          .custom-input-group {
            margin-bottom: 20px;
            width: 100%;
          }
        }
      }
    }
  }
}