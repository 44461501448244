@import "../../assets/styles/mainVariables";
@import "../../assets/styles/fonts";
@import "../../assets/styles/mixins";

footer {
  width: 100%;
  background-color: #32373F;
  position: relative;
  color: $brandColor;

  .footer {
    padding: 70px calc((100% - #{$mainContent}) / 2);
    border-bottom: 1px solid #D5B88333;
    display: flex;
    justify-content: space-between;
    position: relative;

    &__content {
      width: 70%;
      display: flex;
      justify-content: space-between;
    }

    &__links {
      max-width: 25%;
      font-size: 13px;
      line-height: 20px;
      display: block;
      letter-spacing: 1px;

      &__item, a {
        width: 100%;
        overflow: hidden;
        //white-space: nowrap;
        //text-overflow: ellipsis;
        margin-bottom: 6px;
        cursor: pointer;
        font-size: 14px;
        line-height: 22px;
        display: block;
        align-items: center;
        color: $brandColor;
        svg{
          position: relative;
          top: 6px;
        }
      }
      &__title {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 15px;
        letter-spacing: 1px;
        font-weight: 600;
      }
    }

    .social_wrapper {

      &__title {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 15px;
        font-weight: 600;
      }

      .social_items {
        display: flex;

        .social_icon {
          cursor: pointer;
          width: 24px;
          height: 24px;
          background-repeat: no-repeat;
          background-size: cover;
          transition: all 0.2s;
          margin-right: 10px;

          &:hover {
            path {
              fill: white;
            }
          }
        }
      }
    }

    .call-btn {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      position:relative;
      z-index: 1000;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #CDBB99;
      box-shadow: rgba(213, 184, 131, 0.3);
      svg{
        width: 24px;
        height: 24px;
      }
      &::after{
        opacity: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: '';
        height: 100%;
        width: 100%;
        border: 20px solid  #CDBB99;
        border-radius: 100%;
        animation-name: ripple;
        animation-duration: 3s;
        animation-delay: 0s;
        animation-iteration-count: infinite;
        animation-timing-function: cubic-bezier(213, 184, 131, 0.3);
        z-index: -1;
      }

      &::before{
        opacity: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: '';
        height: 100%;
        width: 100%;
        border: 20px solid  #CDBB99;
        border-radius: 100%;
        animation-name: ripple;
        animation-duration: 3s;
        animation-delay: 0.5s;
        animation-iteration-count: infinite;
        animation-timing-function: cubic-bezier(213, 184, 131, 0.3);
        z-index: -1;
      }

    }
    @keyframes ripple {
      from {
        opacity: 1;
        transform: scale3d(0.85,0.85,1);
      }

      to {
        opacity: 0;
        transform: scale3d(1.5,1.5,1);
      }
    }

    .right_part {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      color: white;

      .develop_wrapper {
        font-size: 13px;
        line-height: 18px;
        display: flex;
        align-items: center;
        color: #FFFFFFCC;
        svg{
          margin-left: 10px;
        }
      }
    }
  }

  .copyright {
    padding: 15px calc((100% - #{$mainContent}) / 2);
    color: $brandColor;
  }

  @media all and (max-width: 1300px) {
    .footer {
      padding: 40px calc((100% - #{$mainContent}) / 2);

      &__content {
        max-width: 80%;
      }
    }
  }

  @media all and (max-width: 1050px) {
    .footer {
      padding: 40px $mainPadding;
      //flex-direction: column-reverse;

      &__content {

      }

      &__links {
        font-size: 13px;
        line-height: 20px;
        margin-right: 16px;
        max-width: 30%;

        a {
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 6px;
        }

        &__title {
          font-size: 16px;
          line-height: 22px;
          margin-bottom: 12px;
        }
      }

      .social_wrapper {
        margin-bottom: 30px;
        padding-left: 20px;

        &__title {
          font-size: 16px;
          line-height: 22px;
          margin-bottom: 12px;
        }

        .social_items {
          display: flex;
        }
      }
    }

    .copyright {
      padding: 15px $mainPadding;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media all and (max-width: 700px) {
    .footer {
      flex-direction: column;

      &__content {
        width: 100%;
        max-width: 100%;
        flex-wrap: wrap;
        justify-content: space-between;
      }

      &__links {
        width: 48%;
        max-width: 48%;
        margin-right: 0;

        &__title {
          font-size: 13px;
          line-height: 22px;
          margin-bottom: 12px;
        }
        &:nth-child(4){
          width: 46%;
          max-width: 46%;
          margin-top: 60px;
        }
      }

      .social_wrapper {
        padding-left: 0;
        padding-right: 5%;
        margin-top: 60px;

        &__title {
          font-size: 14px;
          line-height: 22px;
          margin-bottom: 12px;
        }
      }

     .right_part{
       flex-direction: row-reverse;
     }
    }
  }
}
