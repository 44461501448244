.PrivacyWrapper {
  width: 96%;
  padding: 40px 0;
  font-weight: 400;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;

  img{
    width: 48%;
  }

  .text{
    width: 50%;
    .privacy-policy-text *{
      font-family: inherit !important;
    }
  }
  @media screen and (max-width: 1000px){
    width: 100%;
    padding:40px 20px;
    background-color: white;
  }

  @media screen  and (max-width: 850px){
    flex-direction: column-reverse;
    img{
      width: 80%;
    }
    .text{
      width: 80%;
    }
  }
}