
:root {
  --brandColor: #CDBB99;
  --brandSecColor: #CDBB991F;
  --mainPadding: 30px;
  --mainContent: 1440px;
  --halfContent: calc(50% - 100px);
  --headerHeight: 72px;
  --elementMainHeight: 42px;
}

$mainPadding: var(--mainPadding);
$mainContent: var(--mainContent);
$halfContent: var(--halfContent);
$headerHeight: var(--headerHeight);
$elementMainHeight: var(--elementMainHeight);

//--------Colors---------

$brandColor: var(--brandColor);
$brandSecColor: var(--brandSecColor);
$bgColor: #FFFEFE;
$darkColor: #1F2226;
$greyColor: #F4F4F4;
$darkGreyColor: #E2E2E2;
$borderColor: #E0E0E0;
$textColor: #282828;
$greyTextColor: #202020;
$redColor: #ef5151;

@media (max-width: 1680px) {
  :root {
    --mainContent: 1200px;
  }
}

@media (max-width: 1380px) {
  :root {
    --mainContent: 1160px;
    --halfContent: calc(50% - 60px);
  }
}
@media (max-width: 1280px) {
  :root {
    --mainContent: 1000px;
  }
}
@media (max-width: 1180px) {
  :root {
    --mainContent: 1000px;
  }
}
@media (max-width: 1024px) {
  :root {
    --headerHeight: 56px;
    --mainContent: 100%;
  }
}

@media (max-width: 800px) {
  :root {
    --mainPadding: 20px;
  }
}

@media (max-width: 550px) {
  :root {
    --mainPadding: 16px;
  }
}
