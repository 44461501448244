@import "../.././assets/styles/mainVariables";
@import "../.././assets/styles/fonts";

.contact_wrapper{
  width: 100%;
  margin: auto;

  &__header{
    .header{
      &__block{
        &__title{
          width: 20%;
        }
      }
    }
  }

  &__components{
    width: #{$mainContent};
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .components{
    &__leftBlock{
      width: 46%;
      display: flex;
      flex-direction: column;
      align-items:flex-start;
      justify-content: space-between;

      &__title{
        font-size: 24px;
        font-weight: 500;
        line-height: 29px;
        letter-spacing: 0;
        margin-bottom: 12px;
      }

      &__description{
        font-weight: 400;
        color: #858585;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: 0.4px;
        margin-bottom: 74px;
      }
    }

    &__rightBlock{
      width: 46%;
      display: flex;
      flex-wrap: wrap;
    }
  }
  .line{
    background-color: #E0E0E0;
    width: 1px;
    height: 338px;
  }
  .inputs_wrapper{
    &__top{
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 36px;

      .custom-input-group{
        width: 48%;
        input{
          width:100%;
        }
      }
    }

    &__bottom{
      width: 100%;
      margin-bottom: 80px;
      .custom-input-group{
        width: 100%;
        margin-bottom: 36px;
      }

      &__send_btn{
        background-color:#F6F3ED;
        color: #B79B7A;

        &:hover{
          background-color: #CDBB99;
          color:white;
        }

        span{
          margin-left: 10px!important;
        }
      }
      .VTghr {
        svg{
         margin-left: unset;
        }
        .tick-icon{
          polygon{
            fill:#B79B7A!important;
          }
        }
      }
    }
  }

  .info_blocks{
    width:calc(98% / 2);
    display: flex;
    align-items: center;
    padding-bottom: 18px;

    &__icon{
      width: 46px;
      height: 46px;
      border-radius: 4px;
      background-color: #F8F5F2;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:nth-child(3){
      margin-bottom: unset;
      margin-top: 80px;
    }
    &:nth-child(4){
      margin-bottom: unset;
      margin-top: 80px;
    }

    &__items{
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      margin-left: 24px;
      .contact-dropDown{
        width: unset!important;
      }

      &__title{
        color:#B79B7A;
        font-size: 15px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0;
      }

      &__descriptions{
        color: #858585;
        font-size: 15px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.02em;
        text-align: left;

      }
    }
  }

  @media screen and (max-width: 1050px){
      &__components{
        padding: 0 20px;
      }
    .info_blocks{
      width: 50%;
    }
  }

  @media screen and (max-width: 820px){
    &__header{
      .header{
        &__block{
          &__title{
            right: 40%;
            width: 37%;
          }
        }
      }
    }
    &__components{
      flex-direction: column;
    }

    .components{
      &__leftBlock{
        width: 100%;
      }

      &__rightBlock{
        width: 100%;
        align-items: center;
      }
    }
    .inputs_wrapper{
      &__top{
        flex-direction: column;
        .custom-input-group{
          width: 100%;

          &:last-child{
            margin-top: 20px;
          }
        }
      }
    }
    .line{
      width: 100%;
      height: 1px;
      margin-bottom: 80px;
    }
    .info_blocks{
      justify-content: center;
      flex-direction: column;

      &__items{
        align-items: center;
        margin-left: unset;
        margin-top: 14px;

        &__title{
          margin-bottom: 8px;
        }
      }
    }
  }
}



