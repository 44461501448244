@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/mixins";

.productCardGrid-wrapper {
  width: calc(100%);
  text-decoration: unset;

  .post_content {
    border: 1px solid transparent;
    padding: 16px;
    position: relative;
    transition: all .3s;
    background-color: white;
    width: 100%;
    min-height: 100%;

    .post_date {
      font-size: 12px;
      margin: 10px 0px 6px 0px;
      font-weight: 500;
      line-height: 15px;
      color: #858585;
    }

    .post_title {
      margin-bottom: 4px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      height: 18px;
      font-size: 15px;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0.02em;
      color: #1F2226;
    }

    .post_address {
      display: flex;
      align-items: center;
      color: #858585;
      margin-bottom: 28px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      height: 16px;
      font-size: 13px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0.015em;

      svg {
        width: 10px;
        height: 11px;
        margin-right: 4px;
      }
    }

    .priceBlock {
      display: flex;
      flex-direction: column-reverse;
    }

    .price {
      font-size: 20px;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0.02em;
      text-align: left;
      margin-bottom: 4px;
      color: #1F2226;

      .contractual {
        font-size: 16px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0.02em;
        text-align: left;
        color: #1F2226;

        span {
          font-size: 14px;
          font-weight: 600;
          line-height: 18px;
          letter-spacing: 0em;
          text-align: left;
          color: #1F2226;
        }
      }
    }

    .rentPrice {
      font-size: 18px;
      font-weight: 400;
      line-height: 32px;
      letter-spacing: 0em;
      text-align: left;
      color: #1F2226;
      margin-top: 4px;

      .rent {
        font-size: 15px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
        color: #1F2226;
      }

      .contractual {
        font-size: 18px;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;
        color: #1F2226;

        span {
          font-size: 15px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: 0em;
          text-align: left;
          color: #1F2226;
        }
      }
    }

    .information {
      color: black;
      margin-bottom: 16px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      height: 20px;
      font-size: 13px;
      font-weight: 500;
      line-height: 15px;
    }

    .call_btn {
      position: relative;
      overflow: hidden;
      height: 40px;
      width: 100%;
      opacity: 0;

      &.show {
        opacity: 1;
      }
    }

    &:hover {
      border: 1px solid #E0E0E0;
      z-index: 10;
    }

    @media (max-width: 1280px) {
      padding: 8px;
    }

    @media (max-width: 1050px) {
      padding: 10px;

      .call_btn {
        opacity: 1;
      }

      &:hover {
        box-shadow: unset;
        border: 1px solid white;
        z-index: 10;
      }
    }
  }

  &:hover {
    .post_content {
      .post_title {
        cursor: pointer;
        color: #CDBB99;
      }
    }
  }

}