@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/fonts";

.subscribe_info {
  width: 100%;
  padding: 64px calc((100% - #{$mainContent}) / 2);
  //background-color: #CDBB991F;

  .VTghr{
    span{
      margin-right: 10px;
    }
    svg{
      margin-left: unset;
    }
  }

  &__title{
    font-weight: 600;
    font-size: 24px;
    line-height: 38px;
    letter-spacing: 0.02em;
    margin-bottom: 42px;
  }

  &__form{
    display: flex;
    width: 100%;
    .custom-input-group{
      width: calc(100% - 119px);

      input{
        border-right: 0;
        padding: 8px 24px;
        height: 52px;
      }
    }

    .subscribe_btn{
      &:hover{
        background-color:#B79B7A;
      }
    }
  }

  @media screen and (max-width: 1050px){
    padding: 20px;
  }
  @media screen and (max-width: 700px) {

    &__form {
      width: 100%;
      .custom-input-group{
        input{
          height: 44px;
        }
      }
    }
  }
}