@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/fonts";

.contact_info {
  width: 100%;
  padding: 64px calc((100% - #{$mainContent}) / 2);
  background-color: #CDBB991F;
  display: flex;
  justify-content: space-between;

  &__content {
    width: 50%;
    &__title {
      font-size: 24px;
      line-height: 32px;
      letter-spacing: 0.02em;
      margin-bottom: 20px;
      font-weight: 500;
    }

    &__description {
      width: 60%;
      font-size: 15px;
      line-height: 24px;
      margin-bottom: 24px;
      font-weight: 600;
    }

    &__subtitle {
      font-size: 18px;
      line-height: 22px;
      font-weight: 500;
      margin-top: 60px;
      margin-bottom: 30px;
    }

    &__tick_item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 60%;
      margin-bottom: 22px;

      svg {
        width: 28px;
        height: 28px;
      }

      .tick_item_text {
        width: calc(100% - 48px);
        font-size: 14px;
        line-height: 20px;
        transition: 1s;
        display: block;

        &:hover{
          transform: scale(1.09);
          font-weight: 700;
        }
      }
    }

    .question{
      font-size: 18px;
      line-height: 34px;
      letter-spacing: 0.03em;
      margin-bottom: 12px;
      margin-top: 80px;
    }

    .contact_item{
      cursor: pointer;
      display: flex;
      align-items: center;
      color:#1F2226;
      margin-bottom: 8px;
      font-size: 24px;
      font-weight: 400;
      line-height: 34px;
      letter-spacing: 0.03em;
      text-align: left;
      svg{
        margin-right: 15px;
        margin-bottom: 5px;
      }
    }

    &.mobile{
      display: none;
    }

    .home_contact_btn{
      &:hover{
        background-color: #1F2226;
        color: white;
      }
    }
  }

  &__gallery {
    width: 50%;

    &__images {
      width: 100%;

      .first-img-wrapper{
        position: relative;
        padding-top: 54.2%;

        img {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
        }
      }
      .bottom-block{
        width: 100%;
        display: flex;

        .second-img-wrapper{
          width: 50%;
          position: relative;
          padding-top: 48.5%;

          img {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    &__counter {
      width: 100%;
      height: 167px;
      display: flex;
      justify-content: space-between;
      background-color: #CDBB99;
      padding: 30px 40px;

      .counter_item {
        color: #FEFEFE;
        width: 16%;

        &__count {
          font-size: 36px;
          line-height: 52px;
          font-weight: 900;
          letter-spacing: 0.03em;
        }

        &__text {
          font-size: 13px;
          line-height: 22px;
          letter-spacing: 0.06em;
        }
      }
    }
  }

  @media screen and (max-width: 1050px) {
    padding: 64px $mainPadding;
    &__content {
      width: 48%;
      &__description {
        width: 80%;
      }

      &__tick_item {
        width: 80%;
      }
    }

    &__gallery {
      width: 50%;

      &__counter {
        height: 147px;
        padding: 20px 16px;

        .counter_item {
          width: 18%;

          &__count {
            font-size: 30px;
            line-height: 52px;
            letter-spacing: 0.03em;
          }

          &__text {
            font-size: 12px;
            line-height: 18px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 750px) {
    padding: 64px $mainPadding;
    flex-wrap: wrap-reverse;

    &__content {
      width: 100%;

      .home_contact_btn, &__description, &__title{
        display: none;
      }

      &__tick_item {
        width: 100%;
      }


      &.mobile{
        display: block;
        margin-bottom: 60px;
        text-align: center;
        .contact_info__content__title{
          display: block;
        }
        .contact_info__content__description {
          display: block;
          margin: 0 auto 20px;
        }

        .home_contact_btn{
          margin: auto;
          display: block;
        }
      }
    }

    &__gallery {
      width: 100%;

      &__counter {
        height: 147px;
        padding: 20px 16px;

        .counter_item {
          width: 18%;

          &__count {
            font-size: 30px;
            line-height: 52px;
            letter-spacing: 0.03em;
          }

          &__text {
            font-size: 12px;
            line-height: 18px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 400px) {
    &__gallery {
      &__counter {
        padding: 20px 14px;

        .counter_item {
          width: 22%;

          &__count {
            font-size: 28px;
            line-height: 52px;
            letter-spacing: 0.03em;
          }

          &__text {
            font-size: 11px;
            line-height: 14px;
          }
        }
      }
    }
  }
}