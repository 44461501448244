@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/fonts";

.home_slider {
  width: #{$mainContent};
  margin: 0 auto 90px;
  position: relative;

  .slick-slider {
    width: 100%;
    height: 100%;

    .slider-item {
      position: relative;
      padding-top: 35.4%;

      img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
      }
    }

    .slick-dots {
      display: flex !important;
      flex-direction: column;
      align-items: center;
      position: absolute;
      width: var(--mainPadding);
      right: calc(var(--mainPadding) * (-1) - 4px);
      bottom: unset;
      top: 50%;
      transform: translateY(-50%);

      li {
        button {
          transform: scale(1.3);
        }

        &.slick-active {
          button {
            transform: scale(2);
          }
        }
      }
    }
  }

  .slider-bottom-content {
    width: 100%;
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 0 40px 40px 40px;

    .slider-arrows {
      display: flex;
      padding: 6px 4px;
      background-color: rgba(255, 255, 255, .7);

      .slider-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all .2s;
        border-radius: 4px;
        width: 40px;
        height: 40px;
        background-color: transparent;
        cursor: pointer;

        img, svg {
          width: 22px;
          height: 22px;
        }

        &.prev-btn {
          svg {
            transform: rotate(180deg);
          }
        }
      }

    }

    .slider-filter-wrapper {
      width: 220px;
      height: 64px;
      padding: 0 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: rgba(0, 0, 0, .7);
      cursor: pointer;
      position: relative;

      .slider-filter-title {
        color: white;
        font-weight: 900;
        font-size: 16px;
        word-wrap: break-word;
        width: calc(100% - 20px);

        &::first-line {
          word-spacing: 100vw;
        }
      }

      &:hover svg {
        margin-left: 3px;
      }

      svg {
        transition: .2s;
        width: 16px;
        height: 16px;
      }
    }

    .slider-btns-wrapper {
      flex-grow: 1;
      background-color: #fff;
      display: flex;
      align-items: center;

      .PrimaryButton {
        font-weight: 600;
        height: 80px;
        width: 50%;
        background-color: #fff;

        &:hover .title svg {
          left: 16px;
        }

        .title {
          text-transform: uppercase;
          display: flex;
          align-items: center;
          font-size: 1.2vw;


          svg {
            transition: .2s;
            position: relative;
            left: 12px;
            width: 14px;
            height: 14px;

            path {
              fill: #202020;
            }
          }
        }
      }

      .divider {
        border-left: 1px solid #ccc;
        height: 40px;
      }
    }

    .PrimaryButton {
      font-weight: 600;
      height: 50px;
    }
  }

  .slick-slider {
    height: 100%;

    .slick-list {
      padding: 0;
    }

    .slider-item {

      img {
        width: 100%;
        height:100%;
        object-fit: cover;
      }

      .slider-info-wrapper {
        position: absolute;
        top: 0;
        left: 40px;
        height: 100%;
        width: 100%;
        display: flex;
        color: #fff;

        .info-content {
          position: relative;
          margin-top: 6%;
          width: 100%;
        }

        .slide-title {
          font-weight: 900;
          font-size: 28px;
          line-height: 34px;
          overflow: unset;
          width: 70%;
          text-shadow: 2px 1px 1px rgba(0, 0, 0, 0.3);
          margin-bottom: 15px;
        }

        .slide-description{
          font-size: 18px;
          line-height: 22px;
          text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.3);
        }
      }
    }
  }

  @media screen and (max-width: 700px) {
    .slider-bottom-content {
      padding: 0 $mainPadding $mainPadding;

      .slider-arrows {
        padding: 2px 0;

        .progress-wrapper{
          width: 200px;
        }
      }
    }

    .slick-slider {

      .slider-item {
        padding-top: 73.9%;

        img{
          height: 100%;
        }

        .slider-info-wrapper {
          left: $mainPadding;

          .info-content {
            margin-top: 20px;
          }

          .slide-title {
            font-size: 22px;
            line-height: 28px;
            width: 80%;
            margin-bottom: 6px;
          }

          .slide-description{
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
    }
  }
}