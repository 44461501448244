@import "../../assets/styles/mainVariables";

.verify {

  .title{
    margin-bottom: 16px;
  }
  .confirmation-code{
    display: flex;
    align-self: flex-start;
    font-size: 13px;
    font-weight: 300;
    line-height: 16px;
  }
  .send-btn {
    width: 100%;
    margin-top: 40px;
    margin-bottom: 30px;
  }

  .seconds {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #E0E0E0;

    .resend-btn {
      cursor: pointer;

      &.active {
        color: $brandColor;
      }
    }
    .timer {
      color: $brandColor;
    }
  }

  .code-container {
    width: 100%;
  }
}
