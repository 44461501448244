@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/fonts";

.home_about {
  width: #{$mainContent};
  margin: auto;

  &__title {
    font-size: 24px;
    line-height: 18px;
    margin-bottom: 56px;
    margin-top: 64px;
    font-weight: 600;
  }

  &__list {
    display: flex;
    justify-content: space-between;

    &__item {
      width: 30%;
      display: flex;
      justify-content: space-between;

      .item_icon {
        width: 43px;
      }

      .item_content {
        width: calc(100% - 43px);

        .item_title {
          font-size: 20px;
          line-height: 24px;
          font-weight: 600;
          margin-bottom: 10px;
        }

      }
    }
  }

  @media screen and (max-width: 1050px) {
    padding: 8px $mainPadding;

    &__title {
      margin: 0 0 30px ;
    }

    &__list {

      &__item {
        width: 32%;
        .item_icon {
          width: 26px;
          margin-right: 10px;
        }

        .item_content {
          width: calc(100% - 36px);

          .item_title {
            font-size: 18px;
            line-height: 22px;
            margin-bottom: 10px;
          }
          .item_description {
            font-size: 13px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 550px) {
    padding: 8px $mainPadding;
    &__list {
    flex-wrap: wrap;

      &__item {
        width: 100%;
        margin-bottom: 50px;


        .item_content {
          .item_title {
            font-size: 18px;
            line-height: 22px;
            margin-bottom: 10px;
          }
          .item_description {
            font-size: 13px;
          }
        }
      }
    }
  }
}