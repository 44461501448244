@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/fonts";
@import "../../../assets/styles/mixins";


.search_section {
  width: 100%;
  margin-bottom: 40px;
  padding: 70px 30px;
  position: relative;

  .section_title{
    color: $darkColor;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.02em;
    font-weight: 500;
    margin-bottom: 32px;
  }

  &__slider {
    width: 100%;
    position: relative;

    &__arrow {
      width: 32px;
      height: 64px;
      background-color: #7C7C7C1F;
      position: absolute;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.2s;
      cursor: pointer;
      top: 50%;

      &.prev {
        left: -50px;

        svg {
          transform: rotate(180deg);
        }
      }

      &.next {
        right: -80px;
      }
      &.next, &.prev {
        svg {
          path{
            stroke: #858585;
            transition: all 0.2s;
          }
        }
      }
      &:hover {
        background-color: $brandColor;
        &.next, &.prev {
          svg {
            path{
              stroke: white;
            }
          }
        }
      }
    }
  }

  &__list {
    position: relative;
    overflow: hidden;
    width: calc(100% + 40px);

    .slick-track {
      margin-left: unset;
      padding: 30px 0 90px 0;
    }

    .product-card-wrapper {
      width: 100%;
      padding: 0 10px;
    }
  }

  .ant-tabs {

    .ant-tabs-nav:before {
      border-bottom: 1px solid $borderColor;
    }

    .ant-tabs-nav-wrap {
      .ant-tabs-nav-list {
        .ant-tabs-tab {
          font-size: 14px;
          line-height: 18px;
          text-align: center;
          letter-spacing: 0.02em;
          padding: 10px 10px;

          &.ant-tabs-tab-active {
            border-color: black;

            &:before {
              border-color: black;
            }

            .ant-tabs-tab-btn {
              color: black;
            }
          }

          &:hover {
            color: black;
          }
        }

        .ant-tabs-ink-bar {
          background: black;
          height: 1px;
        }
      }
    }

    .ant-tabs-content-holder {
      min-height: 440px;
    }
  }

  .all_btn{
    display: none;
  }

  @media all and (max-width: 1350px) {
    padding: 70px 40px;
    &__slider {

      &__arrow {

        &.prev {
          left: -50px;
        }

        &.next {
          right: -80px;
        }

        &:hover {
          background-color: $brandColor;
        }
      }
    }
  }

  @media all and (max-width: 1200px) {
    &__slider {

      &__arrow {
        &:hover {
          background-color: $brandColor;
        }
      }
    }
  }

  @media all and (max-width: 1100px) {
    &__slider {

      &__arrow {
        display: none;
      }
    }

    &__list{
      width: 100%;
    }
  }

  @media all and (max-width: 1050px) {
    margin-bottom: 0;
    padding:unset;

    .section_title{
      padding: 0 20px;
    }
    .all_btn{
      display: block;
    }
    &__header{
      width: 100%;
      flex-direction: column;
    }
  }
}
