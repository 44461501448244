@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/mixins";

.settings-wrapper {
  width: 100%;
  padding: 0 40px 80px 60px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .back{
    display: none;
  }
  .fields-wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    .top-title{
      font-size: 24px;
      font-weight: 500;
      line-height: 29px;
      letter-spacing: 0;
      text-align: left;
      margin-bottom: 28px;
    }

    .top-wrapper{
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 80px;
      border-bottom: 1px solid #E0E0E0;
      .user-info{
        width: 46%;
        display: flex;
        flex-direction: column;
        .custom-input-group{
          &:last-child{
            margin-top: 20px;
          }
        }
      }
      .date_wrapper{
        width: 46%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .save-btn{
          width: 66%;
          align-self: flex-end;
        }
        .data-inputs{
          label{
            font-size: 15px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.02em;
            text-align: left;

          }
          .inputs{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 12px;
            .custom-input-group{
              width: calc(96% / 3);
            }
          }
        }
      }
    }

    .bottom-wrapper{
      .bottom-title{
        font-size: 24px;
        font-weight: 500;
        line-height: 29px;
        letter-spacing: 0;
        text-align: left;
        margin: 80px 0 28px 0;
      }
      .password-wrapper{
        width: 100%;
        display: flex;
        justify-content: space-between;

        .left-wrapper{
          width: 46%;
          display: flex;
          flex-direction: column;
          .custom-input-group{
            &:last-child{
              margin-top: 20px;
            }
            input{
              border-radius: 2px;
            }
          }
        }
        .right-wrapper{
          width: 46%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .custom-input-group{
            input{
              border-radius: 2px;
            }
          }
          .changePassword{
            width: 66%;
            align-self: flex-end;
          }
        }
      }
    }
  }

  @media screen and (max-width: 800px){
    padding: unset;
    .mobile-back{
      display: flex;
      align-items: center;
      margin-bottom: 26px;
      span{
        font-size: 13px;
        font-weight: 500;
        line-height: 16px;
        color: #C1C1C1;
        margin-left: 12px;
        margin-top: 2px;
      }
    }
    .fields-wrapper{
      .top-wrapper{
        flex-direction: column;
        .user-info{
          width: 100%;
        }
        .date_wrapper{
          width: 100%;
          margin-top: 22px;
          .save-btn{
            width: 100%;
          }
          .data-inputs{
            margin-bottom: 40px;
          }
        }
      }
    }
    .bottom-wrapper{
      .password-wrapper{
        flex-direction: column;
        .left-wrapper{
          width: 100%!important;
        }
        .right-wrapper{
          margin-top: 22px;
          width: 100%!important;
          .changePassword{
            width: 100%!important;
            margin-top: 40px;
          }
        }
      }
    }
  }
}
