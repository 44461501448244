@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/fonts";
@import "../../../assets/styles/mixins";

.home_filter {
  width: #{$mainContent};
  margin: auto;

  &__form {
    display: flex;
    align-items: flex-end;
    margin-bottom: 30px;

    .switch {
      display: flex;
      border: 1px solid $borderColor;
      border-radius: 2px;
      height: 42px;
      width: 15%;
      min-width: 180px;
      margin-right: 2%;

      span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
        height: 100%;
        cursor: pointer;
        transition: all 0.2s;

        &:first-child {
          border-right: 1px solid $borderColor;
        }
        &.active, &:hover {
          background-color: $brandColor;
          color: white;
        }
      }
    }

    .home_filter_region{
      width: 240px;
      margin-right: 2%;
      .home_filter_region_title{
        color: #32373F;
        font-size: 15px;
        font-weight: 500;
        line-height: 18px;
        margin-bottom: 16px;
      }
      .filter_region_block{
        width: 176px;
        height: 42px;
        border: 1px solid #E0E0E0;
        padding: 9px 15px;
        .ant-dropdown-trigger{
          display: flex;
          .ant-space-align-center{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .ant-space-item{
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              display: flex;
              width: 132px;
              margin-bottom: 8px;
              .region-input-text{
                font-size: 15px;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0.02em;
                text-align: left;
                color: #858585;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
              }
            }
          }
        }
      }
    }
    &__select {
      width: 22%;
      display: flex;
      flex-direction: column;

      .label {
        color: #32373F;
        font-size: 15px;
        font-weight: 500;
        line-height: 18px;
        margin-bottom: 16px;
      }
    }

    &__slider {
      width: 37%;
      margin: 0 2%;
      display: flex;
      flex-direction: column;

      .label {
        color: #32373F;
        font-size: 15px;
        font-weight: 500;
        line-height: 18px;
        margin-bottom: 16px;
      }

      .slider_content {
        width: 100%;
        border: 1px solid $borderColor;
        height: 42px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-radius: 2px;

        .slider_label {
          width: 100%;
          display: flex;
          justify-content: space-between;
          padding: 9px 15px;

          span{
            color: #858585;
            margin-right: 6px;
          }
        }

        .ant-slider {
          width: 90%;
          margin: 0 auto;
          height: unset;
          padding: 0;

          .ant-slider-rail {
            background-color: transparent;
          }

          .ant-slider-track {
            background-color: $brandColor;
            height: 2px;
          }

          .ant-slider-handle {
            border: 0;
            background-color: $brandColor;
            width: 10px;
            height: 10px;
            margin-top: -4px;
          }

          .ant-slider-handle-dragging.ant-slider-handle-dragging.ant-slider-handle-dragging {
            box-shadow: 0 0 0 5px rgba(47, 47, 47, 0.1);
            border-color: $borderColor;
          }

          .ant-slider-handle:focus {
            box-shadow: 0 0 0 5px rgba(47, 47, 47, 0.1)
          }

          &:hover {
            .ant-slider-track {
              background-color: $brandColor;
            }

            .ant-slider-rail {
              background-color: transparent;
            }
          }
        }
      }
    }
  }

  &__search {
    display: flex;
    width: 100%;

    .custom-input-group {
      width: calc(100% - 10px);

      .search_input {
        padding: 8px 15px 8px 40px;
      }
    }

    .search_icon {
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
    }

    &__btn {
      width: 100px;
      border-radius: unset!important;
      &:hover{
        background-color: #B79B7A;
      }
    }
  }

  &__clear_wrapper {
    display: flex;
    align-items: center;
    padding: 38px 0 30px 0;
    margin-bottom: 60px;
    border-bottom: 1px solid $borderColor;

    .clear_btn {
      display: flex;
      color: #858585;
      cursor: pointer;
      font-size: 15px;
      margin-right: 30px;
      transition: color 0.2s;
      svg {
        margin-right: 10px;
      }
      &:hover{
        color: #CDBB99;
        svg path{
          fill:  #CDBB99;
        }
      }
      &.disabled{
        color: #C1C1C1;
        svg path{
          fill:  #C1C1C1;
        }
      }
    }
    .refine_btn{
      font-size: 15px;
      color: $brandColor;
      cursor: pointer;
    }
  }

  @media all and (max-width: 1050px) {
    padding: 0 $mainPadding;
  }

  @media all and (max-width: 800px) {
    padding: 0 $mainPadding;
    &__form {
      flex-wrap: wrap;
      justify-content: space-between;

      .switch {
        width: 100%;
        margin-bottom: 30px;
      }
      .home_filter_region{
        width: 100%;
        margin-right: unset;
        margin-bottom: 30px;
        .filter_region_block{
          width: 100%;
          .ant-dropdown-trigger{
            .ant-space-align-center{
              width: 96%;
              .ant-space-item{
                width: 100%;
              }
            }
          }
        }
      }

      &__select {
        width: 48%;
        order: 2;
      }

      &__slider {
        width: 100%;
        order: 3;
        margin: 30px 0 0;
      }
    }
  }
}

.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  border-color: $borderColor !important;
}

.home_filter__form__slider .slider_content .ant-slider .ant-slider-handle:focus{
  box-shadow: none!important;
}

.slick-slider {
  height: 100%;

  .slick-list {
    padding: 0;
  }

  .slider-item {

    img {
      width: 100%;
      height: calc(100vh - 60px);
      object-fit: cover;
      //filter: blur(8px);
    }

    .slider-info-wrapper {
      position: absolute;
      top: 0;
      left: $mainPadding;
      height: 100%;
      width: 100%;
      display: flex;

      .info-content {
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        height: 45%;
        position: relative;
        margin-top: 7%;
        width: 100%;
      }

      .slide-title {
        font-weight: 900;
        font-size: 62px;
        overflow: hidden;
        line-height: 90px;
        width: 50%;
        height: 176px;
      }

      .slide-sub-title {
        font-weight: 400;
        font-size: 20px;
        line-height: 34px;
        width: 50%;
        height: 122px;
        overflow: hidden;
        letter-spacing: 0.4px;
      }

      .link-button {
        width: 218px;
        height: 60px;
      }
    }
  }
}


